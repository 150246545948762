import axios, { AxiosResponse } from 'axios';
import { Storage } from 'react-jhipster';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from './reducer.utils';

import { AppThunk } from 'app/config/store';
import { setLocale } from 'app/shared/reducers/locale';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const AUTH_TOKEN_KEY = 'jhi-authenticationToken';
const ID = 'id';
const GPHC_VERIFY = 'gphc_verify';
const USER_NAME = 'username';
const FIRST_NAME = 'firstName';
const LAST_NAME = 'lastName';
const PHARMACY_SELECTED = 'pharmacySelected';
const SERVICE_SELECTED = 'serviceSelected';
const UPLOADEDFILENAME = 'uploadedFileName';
const PHARMACY_NAME = 'pharmacy_name';
const MANAGER_NAME = 'manager_name';
const ROLE = 'role';
const LOCUM_VERIFIED = 'locumVerified';

export const initialState = {
  loading: false,
  userCurrentRole: null as string,
  isAuthenticated: false,
  isPharmacist: false,
  isDEO: false,
  isLeadDEO: false,
  isManagerDEO: false,
  isClient: false,
  isAdmin: false,
  isLocumDetailsVerify: false,
  loginSuccess: false,
  loginError: false, // Errors returned from server side
  showModalLogin: false,
  account: {} as any,
  errorMessage: null as unknown as string, // Errors returned from server side
  redirectMessage: null as unknown as string,
  sessionHasBeenFetched: false,
  logoutUrl: null as unknown as string,
  successMessage: null as unknown as string,
  idToken: null as unknown as string,
  pharmacy_list: [] as any,
  authorities: [] as any,
  sessionList: [] as any,
  sessionListCount: null as unknown as number,
  pharmacyID: null as unknown as string,
};

export type AuthenticationState = Readonly<typeof initialState>;

// Actions

export const getSession: () => any = () => async (dispatch, getState) => {
  await dispatch(getAccount());
  const { account } = getState().authentication;
  if (account && account.langKey) {
    const langKey = Storage.session.get('locale', account.langKey);
    await dispatch(setLocale(langKey));
  }
};

export const getAccount = createAsyncThunk('authentication/get_account', async () => axios.get<any>('api/account'), {
  serializeError: serializeAxiosError,
});

interface IAuthParams {
  username: string;
  password: string;
  rememberMe?: boolean;
}

interface ISetPasswordParam {
  token: string;
  password: string;
}
interface IForgotPasswordParam {
  username: string;
}

interface ILocumParams {
  id: number;
  first_name: string;
  last_name: string;
  gphc_number: string;
}

export const authenticate = createAsyncThunk(
  'authentication/login',
  async (auth: IAuthParams) => axios.post<any>('api/authenticate', auth),
  {
    serializeError: serializeAxiosError,
  }
);

export const setUserPassword = createAsyncThunk('authentication/set_password', async (params: ISetPasswordParam) => {
  const requestUrl = `api/set-password`;
  return axios.post(requestUrl, params);
});
export const forgotUserPassword = createAsyncThunk('authentication/forgot_password', async (params: IForgotPasswordParam) => {
  const requestUrl = `api/forgot-password`;
  return axios.post(requestUrl, params);
});

export const login: (username: string, password: string, rememberMe?: boolean) => any =
  (username, password, rememberMe = false) =>
  async dispatch => {
    const result = await dispatch(authenticate({ username, password, rememberMe }));
    const response = result.payload as AxiosResponse;
    const bearerToken = response?.data?.id_token;

    if (bearerToken) {
      const jwt = bearerToken;
      if (rememberMe) {
        Storage.local.set(AUTH_TOKEN_KEY, jwt);
      } else {
        Storage.local.set(AUTH_TOKEN_KEY, jwt);
      }
    }
    await dispatch(getSession());
  };

export const authenticateLocumDetail = createAsyncThunk(
  'authentication/locum_details',
  async (locumParams: ILocumParams) => axios.post<any>('api/app-users/locum_details', locumParams),
  {
    serializeError: serializeAxiosError,
  }
);
export const logoutUser = createAsyncThunk('api/logout', async (id: number) => axios.post<any>(`api/logout/${id}`), {
  serializeError: serializeAxiosError,
});

export const handleLocumLogin: (id: number, first_name: string, last_name: string, gphc_number) => any =
  (id, first_name, last_name, gphc_number) => async dispatch => {
    const result = await dispatch(authenticateLocumDetail({ id, first_name, last_name, gphc_number }));
    const response = result.payload as AxiosResponse;
    if (response.status === 201) {
      await dispatch(setLocumVerify(response?.data?.id));
    }
    await dispatch(getSession());
  };

export const clearAuthToken = () => {
  if (Storage.local.get(AUTH_TOKEN_KEY)) {
    Storage.local.remove(AUTH_TOKEN_KEY);
  }
  if (Storage.session.get(AUTH_TOKEN_KEY)) {
    Storage.session.remove(AUTH_TOKEN_KEY);
  }
  Storage.local.remove(ID);
  Storage.local.remove('nhi_id');
  Storage.local.remove(FIRST_NAME);
  Storage.local.remove(LOCUM_VERIFIED);
  Storage.local.remove(LAST_NAME);
  Storage.local.remove(PHARMACY_SELECTED);
  Storage.local.remove(SERVICE_SELECTED);
  Storage.local.remove(USER_NAME);
  Storage.local.remove(PHARMACY_NAME);
  Storage.local.remove(MANAGER_NAME);
  Storage.local.remove(ROLE);
  Storage.local.remove('pharmacy_id');
  Storage.local.remove(UPLOADEDFILENAME);
  Storage.local.remove('uploadedFileCount');
  Storage.local.remove('uploadedFileDeleteCount');
  Storage.local.remove('pharmacy_rule');
};

export const logout: () => AppThunk = () => dispatch => {
  const id = localStorage.getItem('nhi_id');
  const res = dispatch(logoutUser(Number(id)));
  clearAuthToken();
  dispatch(logoutSession());
};
export const clearAuthentication = messageKey => dispatch => {
  clearAuthToken();
  dispatch(authError(messageKey));
  dispatch(clearAuth());
};

export const setPharmacy = pharmacy => async (dispatch, state) => {
  const encode = btoa(pharmacy.id);
  Storage.local.set('pharmacySelected', encode);
  Storage.local.set('pharmacy_id', pharmacy.id);
  Storage.local.set('pharmacy_name', pharmacy.name);
  Storage.local.set('manager_name', pharmacy.manager_name);
  Storage.local.set('pharmacy_rule', pharmacy?.rule);
  await dispatch(getSession());
};

export const setRole = role => async (dispatch, state) => {
  Storage.local.set('role', role);
  await dispatch(getSession());
};
export const setLocumVerify = locum_detail => async dispatch => {
  Storage.local.set('locumVerified', true);
  await dispatch(getSession());
};

export const getMultiplePharmacy = createAsyncThunk(
  'authentication/get_pharmacy/id',
  async (id: number | string) => axios.get<any>(`api/app-users/getMultiplePharmacy/${id}`),
  {
    serializeError: serializeAxiosError,
  }
);

export const AuthenticationSlice = createSlice({
  name: 'authentication',
  initialState: initialState as AuthenticationState,
  reducers: {
    setCurrentUserRole(state, action) {
      Storage.local.set('role', action.payload);
      return {
        ...state,
        userCurrentRole: action.payload,
      };
    },
    logoutSession() {
      return {
        ...initialState,
        showModalLogin: true,
      };
    },
    authError(state, action) {
      return {
        ...state,
        showModalLogin: true,
        redirectMessage: action.payload,
      };
    },
    clearAuth(state) {
      return {
        ...state,
        loading: false,
        showModalLogin: true,
        isAuthenticated: false,
        isPharmacist: false,
        isAdmin: false,
        isDEO: false,
        isLeadDEO: false,
        isManagerDEO: false,
        isClient: false,
        isLocumDetailsVerify: false,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(authenticate.rejected, (state, action) => ({
        ...initialState,
        errorMessage: action.error.message,
        showModalLogin: true,
        loginError: true,
      }))
      .addCase(forgotUserPassword.rejected, (state, action) => ({
        ...initialState,
        errorMessage: action.error.message,
        showModalLogin: true,
        loginError: true,
      }))
      .addCase(authenticate.fulfilled, (state, action) => {
        const bearerToken = action?.payload?.data?.id_token;
        const decodedToken = jwtDecode(bearerToken);
        return {
          ...state,
          loading: false,
          loginError: false,
          showModalLogin: false,
          loginSuccess: true,
          successMessage: action.payload.data.message,
          isPharmacist: !!decodedToken['authorities'].includes('PHARMACIST'),
          isDEO: !!decodedToken['authorities'].includes('DEO'),
          isLeadDEO: !!decodedToken['authorities'].includes('LEAD_DEO'),
          isManagerDEO: !!decodedToken['authorities'].includes('MANAGER_DEO'),
          isAdmin: !!decodedToken['authorities'].includes('ROLE_ADMIN'),
          isClient: !!decodedToken['authorities'].includes('CLIENT'),
          authorities: decodedToken['authorities'],
        };
      })
      .addCase(forgotUserPassword.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(getAccount.rejected, (state, action) => ({
        ...state,
        loading: false,
        isAuthenticated: false,
        isPharmacist: false,
        isDEO: false,
        isLeadDEO: false,
        isManagerDEO: false,
        isAdmin: false,
        isClient: false,
        isLocumDetailsVerify: false,
        sessionHasBeenFetched: true,
        showModalLogin: true,
        errorMessage: action.error.message,
      }))
      .addCase(getAccount.fulfilled, (state, action) => {
        const isAuthenticated = action.payload && action.payload.data && action.payload.data.activated;
        const isPharmacist = action.payload && action.payload.data && action.payload.data.authorities.includes('PHARMACIST');
        const isDEO = action.payload && action.payload.data && action.payload.data.authorities.includes('DEO');
        const isLeadDEO = action.payload && action.payload.data && action.payload.data.authorities.includes('LEAD_DEO');
        const isManagerDEO = action.payload && action.payload.data && action.payload.data.authorities.includes('MANAGER_DEO');
        const isAdmin = action.payload && action.payload.data && action.payload.data.authorities.includes('ROLE_ADMIN');
        const isClient = action.payload && action.payload.data && action.payload.data.authorities.includes('CLIENT');

        Storage.local.set('id', action.payload.data.appUserId);
        Storage.local.set('nhi_id', action.payload.data.id);
        Storage.local.set('firstName', action.payload.data.firstName);
        Storage.local.set('lastName', action.payload.data.lastName);
        Storage.local.set('username', action.payload.data.login);
        const pharmacyId = Storage.local.get('pharmacy_id');
        return {
          ...state,
          isAuthenticated,
          isPharmacist,
          isDEO,
          isLeadDEO,
          isManagerDEO,
          isClient,
          isAdmin,
          pharmacyID: pharmacyId,
          loading: false,
          sessionHasBeenFetched: true,
          account: action.payload.data,
        };
      })
      .addCase(authenticate.pending, state => {
        state.loading = true;
      })
      .addCase(forgotUserPassword.pending, state => {
        state.loading = true;
      })
      .addCase(getAccount.pending, state => {
        state.loading = true;
      })
      .addCase(getMultiplePharmacy.pending, state => {
        state.loading = true;
      })
      .addCase(getMultiplePharmacy.rejected, (state, action) => ({
        ...state,
        loading: false,
        sessionHasBeenFetched: true,
        showModalLogin: true,
        errorMessage: action.error.message,
      }))
      .addCase(getMultiplePharmacy.fulfilled, (state, action) => {
        return {
          ...state,
          loginError: false,
          showModalLogin: false,
          loginSuccess: true,
          isPharmacist: true,
          pharmacy_list: action.payload.data,
        };
      })
      .addCase(authenticateLocumDetail.pending, state => {
        state.loading = true;
      })
      .addCase(authenticateLocumDetail.rejected, (state, action) => ({
        ...state,
        loading: false,
        sessionHasBeenFetched: true,
        showModalLogin: true,
        errorMessage: action.error.message,
        isPharmacist: false,
        isLocumDetailsVerify: false,
      }))
      .addCase(authenticateLocumDetail.fulfilled, (state, action) => {
        const isLocumDetailsVerify = action.payload && action.payload.data && true;
        return {
          ...state,
          isLocumDetailsVerify,
          loginError: false,
          showModalLogin: false,
          loginSuccess: true,
          isPharmacist: true,
          successMessage: action.payload.data.message,
        };
      })
      .addCase(logoutUser.pending, state => {
        state.loading = true;
      })
      .addCase(logoutUser.rejected, (state, action) => ({
        ...state,
        loading: false,
      }))
      .addCase(logoutUser.fulfilled, (state, action) => {
        return {
          ...state,
          showModalLogin: true,
        };
      });
  },
});

export const { setCurrentUserRole, logoutSession, authError, clearAuth } = AuthenticationSlice.actions;

// Reducer
export default AuthenticationSlice.reducer;
