import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Box, Checkbox, Button, Container, FormControlLabel, Grid, TextField, Typography, Modal, InputAdornment } from '@mui/material';
import './login.scss';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export interface ILoginModalProps {
  handleLogin: (username: string, password: string, rememberMe: boolean) => void;
}

const LoginModal = (props: ILoginModalProps) => {
  const [values, setValues] = useState({ username: '', password: '', rememberMe: false });
  const [showPassword, setShowPassword] = useState(false);
  const [fieldError, setFieldError] = useState({
    username: false,
    password: false,
  });
  const {
    handleSubmit,
    register,
    formState: { errors, touchedFields },
  } = useForm({ mode: 'onTouched' });

  const setFormValues = e => {
    setValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
    if (e.target.name === 'username' || e.target.name === 'password') {
      checkSingleFieldValidations(e);
    }
  };
  const checkSingleFieldValidations = e => {
    if (e.target.value === undefined || e.target.value === '' || e.target.value === null) {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: true }));
    } else {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: false }));
    }
  };

  const checkValidationsBeforeSubmit = () => {
    if (values['username'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['username']: true }));
    }
    if (values['password'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['password']: true }));
    }
  };

  const isAllowedToSubmit = () => {
    checkValidationsBeforeSubmit();
    if (!fieldError['username'] && !fieldError['password'] && values['username'] !== undefined && values['password'] !== undefined) {
      return true;
    } else {
      return false;
    }
  };

  const setCheckBoxValues = e => {
    const { name, checked } = e.target;
    setValues(oldValues => ({
      ...oldValues,
      [name]: checked,
    }));
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      login();
    }
  };

  const login = () => {
    if (isAllowedToSubmit()) {
      props.handleLogin(values.username, values.password, values.rememberMe);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const token = localStorage.getItem('jhi-authenticationToken')
    ? localStorage.getItem('jhi-authenticationToken')
    : sessionStorage.getItem('jhi-authenticationToken');
  return (
    <Box>
      <Container component="main" maxWidth="sm" style={{ marginBottom: 20 }}>
        <Box
          sx={{
            boxShadow: 3,
            borderRadius: 2,
            px: 8,
            py: 6,
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{ mb: 3 }}>
            <img src={'../../../content/logos/pharmacy-solution-logo.png'} className="login-logo" />
          </Box>
          <Typography component="h1" fontWeight={'600'} variant="h6">
            Login to Your Account
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }} onKeyDown={handleKeyDown}>
            <TextField
              margin="normal"
              required
              autoFocus
              fullWidth
              id="username"
              label="User Name"
              error={fieldError['username']}
              name="username"
              onChange={setFormValues}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Enter Password"
              error={fieldError['password']}
              type={showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={setFormValues}
              id="password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" onClick={togglePasswordVisibility}>
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <Grid container direction="row" justifyContent={'space-between'} alignItems="center">
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} textAlign={'start'}>
                <FormControlLabel
                  control={<Checkbox value={true} name={'rememberMe'} onChange={setCheckBoxValues} color="primary" />}
                  label="Stay Login"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} textAlign={'end'}>
                <Link to={'forgotPassword'} className={'forgot-password'}>
                  <Typography>Change/Forgot password?</Typography>
                </Link>
              </Grid>
            </Grid>
            <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2, height: 40 }} type={'button'} onClick={login}>
              LOGIN
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default LoginModal;
