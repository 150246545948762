import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import 'app/config/dayjs';

import React, { useEffect, useState } from 'react';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getSession, logout, logoutUser, setCurrentUserRole } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import Header from 'app/shared/layout/header/header';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { AUTHORITIES } from 'app/config/constants';
import AppRoutes from 'app/routes';
import { permanentDeleteUnSubmittedFiles } from 'app/modules/deo-work-flow/deo-reducer';
import axios from 'axios';

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

// const INACTIVITY_LIMIT = 10 * 60 * 1000; // 15 minutes
// const SessionManager = () => {
//   const dispatch = useAppDispatch();
//   const navigate = useNavigate();
//   let inactivityTimer: ReturnType<typeof setTimeout> | null = null;
//
//   useEffect(() => {
//     let isTabClosing = true;
//
//     const handleBeforeUnload = (event: BeforeUnloadEvent) => {
//       event.preventDefault();
//       event.returnValue = ''; // Necessary for some browsers
//     };
//
//     const handleVisibilityChange = () => {
//       if (document.visibilityState === 'hidden') {
//         localStorage.setItem('isTabClosing', 'true');
//       } else {
//         localStorage.removeItem('isTabClosing');
//       }
//     };
//
//     const resetInactivityTimer = () => {
//       if (inactivityTimer) {
//         clearTimeout(inactivityTimer);
//       }
//       inactivityTimer = setTimeout(() => {
//         dispatch(logout());
//         navigate('/login');
//       }, INACTIVITY_LIMIT);
//     };
//
//     const handleUserActivity = () => {
//       resetInactivityTimer();
//     };
//
//     const handleUnload = () => {
//       if (isTabClosing) {
//         dispatch(logout());
//         navigate('/login');
//       }
//       isTabClosing = false;
//     };
//
//     document.addEventListener('mousemove', handleUserActivity);
//     document.addEventListener('keypress', handleUserActivity);
//     document.addEventListener('click', handleUserActivity);
//     window.addEventListener('beforeunload', handleBeforeUnload);
//     document.addEventListener('visibilitychange', handleVisibilityChange);
//     window.addEventListener('unload', handleUnload);
//
//     resetInactivityTimer();
//
//     return () => {
//       document.removeEventListener('mousemove', handleUserActivity);
//       document.removeEventListener('keypress', handleUserActivity);
//       document.removeEventListener('click', handleUserActivity);
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//       document.removeEventListener('visibilitychange', handleVisibilityChange);
//       window.removeEventListener('unload', handleUnload);
//       if (inactivityTimer) {
//         clearTimeout(inactivityTimer);
//       }
//     };
//   }, [dispatch, navigate]);
//
//   return null;
// };

export const App = () => {
  const role =
    localStorage.getItem('role') === null || localStorage.getItem('role') === '' || localStorage.getItem('role') === undefined
      ? ''
      : JSON.parse(localStorage.getItem('role'));
  const dispatch = useAppDispatch();
  const locumVerify = localStorage.getItem('locumVerified');
  useEffect(() => {
    dispatch(getSession());
    dispatch(getProfile());
  }, []);

  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const activeRole = useAppSelector(state => state.authentication.userCurrentRole);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isLocumDetailsVerify = useAppSelector(state => state.authentication.isLocumDetailsVerify);
  const isAdmin =
    useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN])) && role === 'ROLE_ADMIN';
  const isClient =
    useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.CLIENT])) && role === 'CLIENT';

  const isPharmacist =
    useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.PHARMACIST])) && role === 'PHARMACIST';
  const isDEO = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.DEO])) && role === 'DEO';
  const isLeadDEO =
    useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.LEAD_DEO])) && role === 'LEAD_DEO';
  const isManagerDEO =
    useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.MANAGER_DEO])) && role === 'MANAGER_DEO';
  const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);
  const serviceEntities = useAppSelector(state => state.service.serviceEntities);
  const paddingTop = '0px';

  return (
    <BrowserRouter basename={baseHref}>
      <div className="app-container" style={{ paddingTop }}>
        <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />
        {(isAuthenticated && (isAdmin || isClient || isDEO || isLeadDEO || isManagerDEO)) ||
        (isPharmacist && (locumVerify || isLocumDetailsVerify)) ? (
          <ErrorBoundary>
            <Header
              isAuthenticated={isAuthenticated}
              isAdmin={isAdmin}
              isPharmacist={isPharmacist}
              isDEO={isDEO}
              isLeadDEO={isLeadDEO}
              isManagerDEO={isManagerDEO}
              isClient={isClient}
              isLocumDetailsVerify={isLocumDetailsVerify}
              serviceEntities={serviceEntities}
              currentLocale={currentLocale}
              ribbonEnv={ribbonEnv}
              isInProduction={isInProduction}
              isOpenAPIEnabled={isOpenAPIEnabled}
            />
          </ErrorBoundary>
        ) : null}
        <div className="container-fluid view-container" id="app-view-container">
          <ErrorBoundary>
            <AppRoutes />
          </ErrorBoundary>
          {/*<SessionManager />*/}
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
