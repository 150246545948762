import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TablePagination,
  Tooltip,
  Typography,
} from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { getAllSessionsByPharmacy, partialUpdateEntity } from 'app/entities/session/session.reducer';
import { useNavigate, useParams } from 'react-router-dom';
import { NMS_STATUS, NMS_TYPES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import './listing-overview-table.scss';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import RichDataTable from '../rich-data-table/RichDataTable';
import useOverviewScreen from '../hooks/useOverviewScreen';
import { isValidDate } from 'app/shared/util/utits';
import { getAllSessionsListingWithDateRange } from 'app/modules/admin-work-flow/admin-reducer';
import { ISessionParam } from 'app/modules/admin-work-flow/interface';

interface ISessionListParams {
  service_id: any;
  session_status: any;
  session_type: any;
  pageNo: any;
  searchParams: any;
  rows: any;
  q: any;
}
interface ISessionConsent {
  id: any;
  consent: boolean;
  sessionStatus: any;
  sessionType: any;
}

export const AdminOverviewTable = props => {
  const { states, handlers } = useOverviewScreen();
  const { stats, recentClients, recentRoles, itemsData, searchFilter, searchCreteria } = states;
  const { downloadSummaryInExcelFile, handledateFrom, handledateTill } = handlers;

  const emptyRowCount = 8;
  const emptyCellCount = 4;

  const [selectService, setSelectService] = useState(0);
  const gridData = [
    { icon: '../../../../content/assets/Total Clients.png', heading: 'Total Pharmacy Contractors', heading2: stats.totalClients },
    { icon: '../../../../content/assets/check.png', heading: 'Completed NMS', heading2: stats.totalNMSCompleted },
    // { icon: '../../../../content/assets/Follow Ups.png', heading: 'Follow Ups', heading2: stats.totalFollowUp },
    { icon: '../../../../content/assets/Total Roles.png', heading: 'Total Roles', heading2: stats.totalRoles },
  ];

  return (
    <Grid lg={9.5} xs={8} container style={styles.container}>
      <Grid lg={8.5} xs={12} container style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
        <div className="drop_down_div">
          <Grid lg={3} xs={3} style={{ backgroundColor: '' }}>
            <Tooltip title="Date From">
              <div style={{ display: 'flex' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                  <DatePicker
                    value={searchCreteria.from === '' ? null : searchCreteria.from}
                    // className="clock"
                    format="DD/MM/YYYY"
                    onChange={newValue => handledateFrom(newValue)}
                    sx={{ marginTop: 0, marginLeft: 0, border: '', width: '90%', height: '80%' }}
                  />
                </LocalizationProvider>
              </div>
            </Tooltip>
          </Grid>
          <Grid lg={3} xs={3} style={{ backgroundColor: '' }}>
            <Tooltip title="Date Till">
              <div style={{ display: 'flex' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                  <DatePicker
                    value={searchCreteria.to === '' ? null : searchCreteria.to}
                    className="clock"
                    format="DD/MM/YYYY"
                    onChange={newValue => handledateTill(newValue)}
                    sx={{ marginTop: 0, marginLeft: 0, border: 'black', width: '90%', height: '80%' }}
                  />
                </LocalizationProvider>
              </div>
            </Tooltip>
          </Grid>
          <Grid lg={1} xs={1} style={{ backgroundColor: '' }}>
            <Tooltip title="Download">
              <IconButton
                onClick={() => downloadSummaryInExcelFile()}
                style={{
                  marginLeft: 10,
                  height: 45,
                  width: 45,
                  borderRadius: 100,
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                <img src={'../../../../content/assets/download.png'} alt="logo" style={{ height: '44px', marginRight: 0, marginLeft: 0 }} />
              </IconButton>
            </Tooltip>
          </Grid>
        </div>

        <Grid lg={12} xs={12} style={{ marginTop: 10, marginBottom: 10, display: 'flex', justifyContent: 'space-between' }}>
          {gridData.map((item, index) => (
            <Grid key={index} lg={3.9} xs={3.9} style={{ height: 130, justifyContent: 'center', backgroundColor: 'white' }}>
              <div
                style={{
                  width: '100%',
                  height: 100,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 20,
                }}
              >
                <img src={item.icon} alt="logo" style={{ height: '44px' }} />
                <h1 style={{ fontSize: 13, color: '#707070', marginTop: 10 }}>{item.heading}</h1>
                <h1 style={{ fontSize: 17, marginTop: 5 }}>{item.heading2}</h1>
              </div>
            </Grid>
          ))}
        </Grid>

        <Paper sx={{ width: '100%', paddingTop: '10px', border: 'none' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 10, justifyContent: 'space-between', marginLeft: 15 }}>
            <p style={{ fontSize: 13, color: '#0D61A9', marginBottom: 0 }}>Recently Added Roles</p>
            <img
              src={'../../../../content/assets/setting.png'} // Use the image URL from the array
              alt="logo"
              style={{ height: '28px', marginTop: -7, marginRight: 14 }}
            />
          </div>
          {/* <TableContainer sx={{ maxHeight: 210, border: 'none' }}>
            <Table stickyHeader aria-label="sticky table" style={{ border: 'none', borderTop: '0.1px solid #dadce1' }}>
              <TableHead sx={{ height: 30, border: 'none' }}>
                <TableRow
                  sx={{
                    height: 30,
                    border: 'none',
                    borderTop: '0.1px solid #dadce1',
                    borderBottom: '0.1px solid #dadce1',
                  }}
                >
                  <TableCell
                    sx={{
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    ID
                  </TableCell>

                  <TableCell
                    sx={{
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    Staff Name
                  </TableCell>

                  <TableCell
                    sx={{
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    Role
                  </TableCell>

                  <TableCell style={{ backgroundColor: '#F5F6FA' }}></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {sessionByPharmacyEntities &&
                  sessionByPharmacyEntities.length > 0 &&
                  sessionByPharmacyEntities.map((item, index) => (
                    <TableRow
                      key={index}
                      hover
                      role="complementary"
                      style={{
                        border: 'none',
                        borderTop: '0.1px solid #dadce1',
                        borderBottom: '0.1px solid #dadce1',
                      }}
                    >
                      <TableCell style={{ fontSize: 12 }}>{item.id}</TableCell>
                      <TableCell style={{ backgroundColor: '', fontSize: 12, fontFamily: 'Roboto, sans-serif' }}>
                        {item.patient && item.patient.firstName}
                      </TableCell>

                      <TableCell style={{ backgroundColor: '', fontSize: 12, fontFamily: 'Roboto, sans-serif' }}>
                        {item.patient && item.patient.telephone}
                      </TableCell>
                    </TableRow>
                  ))}
                {Array.from({ length: emptyRowCount }).map((_, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    hover
                    role="complementary"
                    style={{
                      height: 50,
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                    }}
                  >
                    {Array.from({ length: emptyCellCount }).map((_, cellIndex) => (
                      <TableCell
                        key={cellIndex}
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      ></TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer> */}

          <RichDataTable
            emptyRowCount={emptyRowCount}
            emptyCellCount={emptyCellCount}
            dataIndexs={['index', 'internalUser.firstName', 'formattedAuthorities']}
            dataSource={recentRoles}
            headers={['ID', 'Staff Name', 'Role']}
            showPagination={false}
          />
        </Paper>
      </Grid>
      <Grid lg={3} container style={{ backgroundColor: 'white', height: '91%', marginTop: 60, marginLeft: 20 }}>
        <div style={{ width: '100%', marginLeft: 10, marginRight: 10, marginTop: 20 }}>
          <h1 style={{ fontSize: 13, color: '#0D61A9', marginLeft: 10 }}>Recently Added Pharmacy Contractors</h1>
          <div style={{ backgroundColor: '#7CB6F8', height: 1, width: '100%', marginTop: 0 }}></div>

          <div style={{ height: 400, backgroundColor: '', overflow: 'auto' }}>
            {itemsData.map((item, index) => (
              <React.Fragment key={index}>
                <div>
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      height: 50,
                      marginTop: 10,
                    }}
                  >
                    <img
                      src={'../../../../content/assets/profile1.png'}
                      alt="logo"
                      style={{ height: '40px', marginRight: 5, marginLeft: 12, marginTop: 6 }}
                      onClick={() => {}}
                    />

                    <div
                      style={{
                        justifyContent: 'space-between',
                        backgroundColor: '',
                        height: 50,
                        marginTop: 10,
                        marginLeft: 5,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 13,
                          marginLeft: 0.5,
                          fontFamily: 'Roboto, sans-serif',
                          color: '#000000',
                        }}
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 10,
                          marginLeft: 0.5,
                          fontFamily: 'Roboto, sans-serif',
                          color: '#707070',
                        }}
                      >
                        {item.date}
                      </Typography>
                    </div>
                  </div>
                  <div style={{ backgroundColor: '#0000000D', height: 1, width: '100%', marginTop: 20 }}></div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
export default AdminOverviewTable;

interface Styles {
  container: React.CSSProperties;
}

// Define the styles object
const styles: Styles = {
  container: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
    marginTop: 15,
    marginLeft: 20,
    display: 'flex',
  },
};
