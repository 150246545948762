import { useAppSelector, useAppDispatch } from 'app/config/store';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IDeoOverviewSessionParam, IListingPharmacy } from '../interface';
import {
  downloadExcelFileOverdueRecords,
  downloadExcelFileOverviewRecords,
  getAllDeoOverdueSessions,
  getDeletedSessions,
} from '../deo-reducer';
import { deleteEntity } from 'app/entities/session/session.reducer';
import { interventionData, isValidDate, s2ab } from 'app/shared/util/utits';
import * as XLSX from 'xlsx';
import moment from 'moment/moment';
import { getGroupNames } from '../../admin-work-flow/admin-reducer';

const intialState: IListingPharmacy = {
  size: 20,
  page: 0,
  searchQuery: '',
  pharmacy: '',
  from: '',
  to: '',
};
const intialStateOverview: IDeoOverviewSessionParam = {
  size: 20,
  page: 0,
  pharmacist: '',
  pharmacy: '',
  branch: '',
  group: '',
  status: '',
  from: '',
  to: '',
  searchQuery: '',
};

const OverdueHook = () => {
  const [searchCreteria, setsearchCreteria] = useState<IDeoOverviewSessionParam>(intialStateOverview);

  const navigate = useNavigate();
  const [downloadData, setDownloadData] = useState([]);
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [recordId, setRecordId] = useState('');
  const [open, setOpen] = React.useState(false);
  const emptyRowCount = 7;
  const emptyCellCount = 9;

  const overdueListing = useAppSelector(state => state.deo.deoOverdueSession);
  console.log(overdueListing);

  const handleEdit = useCallback(item => {
    navigate(`/admin/edit-pharmacy/${item.id}`);
  }, []);

  useEffect(() => {
    dispatch(getGroupNames());
  }, []);

  const handleRowsChange = event => {
    const pagePerRow = event.target.value;
    setsearchCreteria({
      ...searchCreteria,
      page: 0,
      size: pagePerRow,
    });
    const params = {
      ...searchCreteria,
      page: 0,
      size: pagePerRow,
    };
    dispatch(getAllDeoOverdueSessions(params));
  };

  const handlePageChange = (event, pageNum) => {
    setsearchCreteria({
      ...searchCreteria,
      page: pageNum,
    });
    const params = {
      ...searchCreteria,
      page: pageNum,
    };
    dispatch(getAllDeoOverdueSessions(params));
  };

  const debounce = useCallback((func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args); // Pass the arguments to the debounced function
      }, delay);
    };
  }, []);

  const handleSearch = value => {
    // You can also perform your API call here with the value
    const params = {
      ...searchCreteria,
      searchQuery: value,
    };
    dispatch(getAllDeoOverdueSessions(params));
  };

  const debouncedSearch = debounce(handleSearch, 1500);

  const handleSearchChange = event => {
    const { value } = event.target;
    setsearchCreteria({
      ...searchCreteria,
      searchQuery: value,
    });
    debouncedSearch(value); // Pass the input value to debouncedSearch
  };

  const handledateFrom = e => {
    const date = isValidDate(e);
    const params = {
      ...searchCreteria,
      from: date,
    };
    setsearchCreteria({
      ...searchCreteria,
      from: date,
    });
    dispatch(getAllDeoOverdueSessions(params));
  };

  const handledateTill = e => {
    const date = isValidDate(e);

    const params = {
      ...searchCreteria,
      to: date,
    };
    setsearchCreteria({
      ...searchCreteria,
      to: date,
    });
    dispatch(getAllDeoOverdueSessions(params));
  };

  const handleSelectPharmacy = event => {
    let value = event.target.value;
    value = value === 'All Pharmacies' ? '' : value;
    const params = {
      ...searchCreteria,
      pharmacy: value,
    };
    setsearchCreteria({
      ...searchCreteria,
      pharmacy: value,
    });
    dispatch(getAllDeoOverdueSessions(params));
  };

  const handleSelectBranch = event => {
    let value = event.target.value;
    value = value === 'All Groups' ? '' : value;
    const params = {
      ...searchCreteria,
      group: value,
    };
    setsearchCreteria({
      ...searchCreteria,
      group: value,
    });
    dispatch(getAllDeoOverdueSessions(params));
  };

  const handleSelectService = event => {
    let value = event.target.value;
    value = value === 'All Service Pharmacists' ? '' : value;
    const params = {
      ...searchCreteria,
      pharmacist: value,
    };
    setsearchCreteria({
      ...searchCreteria,
      pharmacist: value,
    });
    dispatch(getAllDeoOverdueSessions(params));
  };

  const handleSelectStatus = event => {
    let value = event.target.value;
    value = value === 'Status' ? '' : value;
    const params = {
      ...searchCreteria,
      status: value,
    };
    setsearchCreteria({
      ...searchCreteria,
      status: value,
    });
    dispatch(getAllDeoOverdueSessions(params));
  };

  const handleClickOpen = id => {
    setRecordId(id);
    setOpen(true);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosed = () => {
    setAnchorEl(null);
  };

  const handleRedirect = id => {
    navigate('/operator/edit-patient/' + id);
  };

  const handleClose = () => {
    setRecordId('');
    setOpen(false);
  };

  const handleDelete = () => {
    if (recordId !== '') {
      dispatch(deleteEntity(recordId))
        .then(result => {
          dispatch(getAllDeoOverdueSessions(searchCreteria));
          dispatch(getDeletedSessions(searchCreteria));
          handleClose();
        })
        .catch(error => {});
    }
  };

  const getDownloadData = useCallback(async () => {
    const params = {
      ...searchCreteria,
    };
    const response: any = await dispatch(downloadExcelFileOverdueRecords(params));
    if (response.type === 'session/download-overdue-excel-file-data/fulfilled') {
      setDownloadData(response.payload.data);
    }
  }, [searchCreteria]);

  const handleExcelFileDownloadRecords = useCallback(() => {
    const groupedData = downloadData.reduce((acc, record) => {
      const status = record.sessionStatus.name;
      if (!acc[status]) {
        acc[status] = [];
      }
      acc[status].push(record);
      return acc;
    }, {});

    const wb = XLSX.utils.book_new();

    Object.keys(groupedData).forEach(status => {
      const dataForStatus = [];
      groupedData[status].forEach((record, index) => {
        if (record.prescription.prescriptionMedicines && record.prescription.prescriptionMedicines.length > 1) {
          record.prescription.prescriptionMedicines.forEach((medicine, medIndex) => {
            const newRecord = { ...record, prescriptionMedicine: medicine };
            dataForStatus.push(interventionData(newRecord, index * 100 + medIndex, medicine)); // Ensure unique index
          });
        } else {
          dataForStatus.push(interventionData(record, index, record.prescription.prescriptionMedicines[0]));
        }
      });

      const ws = XLSX.utils.json_to_sheet(dataForStatus);

      const totalColumns = XLSX.utils.decode_range(ws['!ref']).e.c + 1;
      ws['!cols'] = Array.from({ length: totalColumns }, (_, index) => {
        const columnTextLengths = [];
        for (let R = 0; R <= XLSX.utils.decode_range(ws['!ref']).e.r; R++) {
          const cellAddress = { c: index, r: R };
          const cellRef = XLSX.utils.encode_cell(cellAddress);
          const cellText = ws[cellRef]?.v?.toString() ?? '';
          columnTextLengths.push(cellText.length);
        }
        const maxTextLength = Math.max(...columnTextLengths);
        return { wch: maxTextLength + 2 }; // Adding extra space for better readability
      });

      // Append the worksheet to the workbook
      status = status = 'Overdue';
      XLSX.utils.book_append_sheet(wb, ws, status);
    });

    // Generate XLSX data in binary form
    const xlsxData = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    // Convert the binary data to a Blob
    const blob = new Blob([s2ab(xlsxData)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Create a download link for the Excel file
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `Overdue - ${moment().format('DD-MM-YYYY')}.xlsx`; // Set the file name here
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }, [downloadData]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      // eslint-disable-next-line no-bitwise
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }

  useEffect(() => {
    getDownloadData();
  }, [searchCreteria]);
  useEffect(() => {}, []);

  const states = {
    emptyCellCount,
    emptyRowCount,
    searchCreteria,
    overdueListing,
    anchorEl,
    open,
  };
  const handlers = {
    handleRowsChange,
    handlePageChange,
    handleEdit,
    handleClickOpen,
    handleClick,
    handleClosed,
    handleRedirect,
    handleDelete,
    handleClose,
    handleSearch,
    handleSearchChange,
    handledateFrom,
    handledateTill,
    handleSelectPharmacy,
    handleSelectBranch,
    handleSelectService,
    handleSelectStatus,
    handleExcelFileDownloadRecords,
  };

  return {
    states,
    handlers,
  };
};

export default OverdueHook;
