import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Popover,
} from '@mui/material';
import OverviewHook from '../hooks/overviewHook';
import { DateFilter, SelectFilter, ActionsFilter } from '../filter/filter';
import moment from 'moment';
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';
import { useAppSelector } from 'app/config/store';

export const Overview = props => {
  const { states, handlers } = OverviewHook();
  const loadingDownload = useAppSelector(state => state.deo.loadingOverviewDownload);
  const [visibleColumns, setVisibleColumns] = useState(9);
  const { emptyRowCount, searchCreteria, deoOverviewSessions, anchorEl, open } = states;
  const {
    handleRowsChange,
    handlePageChange,
    handleDelete,
    handleRedirect,
    handleClosed,
    handleClick,
    handleClose,
    handleClickOpen,
    handleSearchChange,
    handleSelectPharmacy,
    handleSelectMonth,
    handleSelectBranch,
    handleSelectService,
    handleSelectStatus,
    handledateFrom,
    handledateTill,
    getDownloadData,
  } = handlers;

  const [columnVisibility, setColumnVisibility] = useState({
    id: true,
    patientName: true,
    dob: true,
    phone: true,
    patientAddress: true,
    medicines: true,
    dateofEngagement: true,
    status: true,
  });

  const handleColumnToggle = columnName => event => {
    const isChecked = event.target.checked;
    setColumnVisibility(prevVisibility => ({
      ...prevVisibility,
      [columnName]: isChecked,
    }));
    if (!isChecked) {
      setVisibleColumns(prevVisibleColumns => prevVisibleColumns - 1);
    } else {
      setVisibleColumns(prevVisibleColumns => prevVisibleColumns + 1);
    }
  };

  const calculateColumnWidth = () => {
    const numVisibleColumns = Object.values(columnVisibility).filter(Boolean).length;
    return `${100 / numVisibleColumns}%`;
  };

  const cellStyle = {
    backgroundColor: '#F5F6FA',
    fontSize: 13,
    borderBottom: '0.1px solid #dadce1',
    fontWeight: 'bold',
    borderTop: '0.1px solid #dadce1',
    border: 'none',
    fontFamily: 'Roboto, sans-serif',
  };

  return (
    <Grid
      lg={12}
      xs={12}
      container
      style={{
        position: 'sticky',
        top: 0,
        backgroundColor: 'transparent',
        marginTop: 20,
        marginLeft: 20,
        height: '87%',
      }}
    >
      <Grid lg={11.6} xs={11.6} style={{ display: 'flex', marginTop: '20px' }}>
        <SelectFilter
          handleSelectPharmacy={handleSelectPharmacy}
          handleSelectBranch={handleSelectBranch}
          handleSelectService={handleSelectService}
          handleSelectStatus={handleSelectStatus}
          handleSelectMonth={handleSelectMonth}
          searchCreteria={searchCreteria}
          isOverview={true}
        />
        <DateFilter handledateFrom={handledateFrom} handledateTill={handledateTill} searchCreteria={searchCreteria} />

        <ActionsFilter getDownloadData={getDownloadData} loadingDownload={loadingDownload} />
      </Grid>

      <Grid
        lg={11.6}
        xs={11.6}
        container
        style={{ backgroundColor: 'white', marginTop: 20, height: '94%', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}
      >
        <div style={{ backgroundColor: 'white', justifyContent: 'center', flex: 1, height: '100%', alignItems: 'center', width: '98%' }}>
          <FormControl sx={{ width: '100%', height: '11%' }}>
            <OutlinedInput
              style={{ borderRadius: 50, height: '100%', width: '100%' }}
              placeholder="Search here"
              onChange={handleSearchChange}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton color="inherit" style={{ height: 30, width: 30 }}>
                    <img
                      src={'../../../../content/assets/search-alt-svgrepo-com (1).png'} // Use the image URL from the array
                      alt="logo"
                      style={{ height: '15px' }}
                    />
                  </IconButton>
                </InputAdornment>
              }
              inputProps={{ style: { fontSize: 13 } }}
              classes={{ notchedOutline: 'white-outline' }}
            />
          </FormControl>

          <TableContainer sx={{ height: '77%' }}>
            <Table stickyHeader aria-label="sticky table" style={{ border: 'none', height: '100%' }}>
              <TableHead>
                <TableRow sx={{ border: 'none' }}>
                  <TableCell sx={{ width: '8%', ...cellStyle }}></TableCell>
                  {columnVisibility.id && <TableCell sx={{ width: '8%', ...cellStyle }}>ID</TableCell>}
                  {columnVisibility.patientName && <TableCell sx={{ width: calculateColumnWidth(), ...cellStyle }}>Patient Name</TableCell>}
                  {columnVisibility.dob && <TableCell sx={{ width: calculateColumnWidth(), ...cellStyle }}>Date of Birth</TableCell>}
                  {columnVisibility.phone && <TableCell sx={{ width: calculateColumnWidth(), ...cellStyle }}>Phone</TableCell>}
                  {columnVisibility.patientAddress && <TableCell sx={{ width: calculateColumnWidth(), ...cellStyle }}>Address</TableCell>}
                  {columnVisibility.medicines && (
                    <TableCell sx={{ width: calculateColumnWidth(), ...cellStyle }}>Medicine(s) to be reviewed</TableCell>
                  )}
                  {columnVisibility.dateofEngagement && (
                    <TableCell sx={{ width: calculateColumnWidth(), ...cellStyle }}>Date of Engagement</TableCell>
                  )}
                  {columnVisibility.status && <TableCell sx={{ width: calculateColumnWidth(), ...cellStyle }}>Status</TableCell>}
                  <TableCell sx={{ width: calculateColumnWidth(), ...cellStyle }}>
                    <MoreVertSharpIcon
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={handleClick}
                    />
                  </TableCell>
                  <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClosed}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    disableScrollLock={true} // Add this line to prevent scroll from hiding
                  >
                    <div style={{ padding: '10px' }}>
                      <div>
                        <FormControlLabel
                          control={<Checkbox disabled checked={columnVisibility.id} onChange={handleColumnToggle('id')} />}
                          label="ID"
                          style={{ cursor: 'not-allowed' }}
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox disabled checked={columnVisibility.patientName} onChange={handleColumnToggle('patientName')} />
                          }
                          label="Patient Name"
                          style={{ cursor: 'not-allowed' }}
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={columnVisibility.dob}
                              onChange={handleColumnToggle('dob')}
                              sx={{
                                '&.MuiCheckbox-root.Mui-checked': {
                                  color: '#21AD64',
                                },
                              }}
                            />
                          }
                          label="Date of Birth"
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={columnVisibility.phone}
                              onChange={handleColumnToggle('phone')}
                              sx={{
                                '&.MuiCheckbox-root.Mui-checked': {
                                  color: '#21AD64',
                                },
                              }}
                            />
                          }
                          label="Phone"
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={columnVisibility.patientAddress}
                              onChange={handleColumnToggle('patientAddress')}
                              sx={{
                                '&.MuiCheckbox-root.Mui-checked': {
                                  color: '#21AD64',
                                },
                              }}
                            />
                          }
                          label="Patient Address"
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          control={<Checkbox disabled checked={columnVisibility.medicines} onChange={handleColumnToggle('medicines')} />}
                          label="Medicines(s) to be reviewed"
                          style={{ cursor: 'not-allowed' }}
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled
                              checked={columnVisibility.dateofEngagement}
                              onChange={handleColumnToggle('dateofEngagement')}
                            />
                          }
                          label="Date of Engagement"
                          style={{ cursor: 'not-allowed' }}
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          control={<Checkbox disabled checked={columnVisibility.status} onChange={handleColumnToggle('status')} />}
                          label="Status"
                          style={{ cursor: 'not-allowed' }}
                        />
                      </div>
                    </div>
                  </Popover>
                </TableRow>
              </TableHead>
              <TableBody>
                {deoOverviewSessions &&
                  deoOverviewSessions?.results &&
                  deoOverviewSessions.results.map((item, index) => (
                    <TableRow key={index} hover role="complementary" style={{ height: 50 }}>
                      <TableCell
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      >
                        <div style={{ justifyContent: 'space-between', width: '53px', display: 'flex', alignSelf: 'center' }}>
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={() => {
                                handleRedirect(item.id);
                              }}
                              style={{
                                backgroundColor: '',
                                marginLeft: 0,
                                height: 25,
                                width: 25,
                                padding: '10px',
                                display: 'flex',
                                borderRadius: 100,
                                cursor: 'pointer',
                                transition: 'background-color 0.3s',
                              }}
                            >
                              <img
                                src={'../../../../content/assets/Edit.png'}
                                alt="logo"
                                style={{ height: '22px', marginRight: 0, marginLeft: 0 }}
                              />{' '}
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() => {
                                handleClickOpen(item.id);
                              }}
                              style={{
                                backgroundColor: '',
                                marginLeft: 0,
                                height: 25,
                                width: 25,
                                padding: '10px',
                                display: 'flex',
                                borderRadius: 100,
                                cursor: 'pointer',
                                transition: 'background-color 0.3s',
                              }}
                            >
                              <img
                                src={'../../../../content/assets/Delete.png'}
                                alt="logo"
                                style={{ height: '22px', marginRight: 0, marginLeft: 0 }}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </TableCell>
                      {columnVisibility.id && (
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          {Number(index) + Number(1)}
                        </TableCell>
                      )}
                      {columnVisibility.patientName && (
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                            width: '150px',
                          }}
                        >
                          {item?.patient?.firstName}
                        </TableCell>
                      )}
                      {columnVisibility.dob && (
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                            width: '200px',
                          }}
                        >
                          {item.patient['dateofBirth']
                            ? moment(item.patient['dateofBirth']).format('DD-MM-YYYY')
                            : moment().format('DD-MM-YYYY')}
                        </TableCell>
                      )}
                      {columnVisibility.phone && (
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          {item?.patient?.telephone}
                        </TableCell>
                      )}
                      {columnVisibility.patientAddress && (
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          {item && item.patient && item.patient.address}
                        </TableCell>
                      )}
                      {columnVisibility.medicines && (
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                            width: '200px',
                          }}
                        >
                          {item && item.prescription && item?.prescription?.prescriptionMedicines.map(pm => pm.medicine.name).join(', ')}
                        </TableCell>
                      )}
                      {columnVisibility.dateofEngagement && (
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          {moment(item?.nextSession).format('DD-MM-YYYY') || moment().format('DD-MM-YYYY')}
                        </TableCell>
                      )}
                      {columnVisibility.status && (
                        <TableCell
                          style={{
                            backgroundColor: '',
                            color: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          {item?.sessionStatus?.name === 'completed' ? (
                            <span style={{ color: 'rgb(33, 173, 100)' }}>Completed</span>
                          ) : item?.sessionStatus?.name === 'not_reacheable' ? (
                            <span style={{ color: '#FFCC60' }}>No Answered</span>
                          ) : item?.sessionStatus?.name === 'no_consent' ? (
                            <span style={{ color: 'rgb(212,52,52)' }}>No Consent</span>
                          ) : item?.sessionStatus?.name === 'in_eligible' ? (
                            <span style={{ color: '' }}>Ineligible</span>
                          ) : item?.sessionStatus?.name === 'incorrect_number' ? (
                            <span style={{ color: 'rgb(212,52,52)' }}>Incorrect Number</span>
                          ) : item?.sessionStatus?.name === 'patient_left' ? (
                            <span style={{ color: '' }}>Patient Left Pharmacy</span>
                          ) : item?.sessionStatus?.name === 'intervention_not_delivered' ? (
                            <span style={{ color: '' }}>Intervention Not Delivered</span>
                          ) : item?.sessionStatus?.name === 'in_progress' && item?.prevSessionType.name === 'intervention' ? (
                            <span style={{ color: '' }}>Pending Intervention</span>
                          ) : item?.sessionStatus?.name === 'in_progress' && item?.prevSessionType.name === 'follow_up' ? (
                            <span style={{ color: '' }}>Pending Follow-ups</span>
                          ) : (
                            ''
                          )}
                        </TableCell>
                      )}
                      <TableCell
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      ></TableCell>
                    </TableRow>
                  ))}
                {Array.from({ length: emptyRowCount }).map((_, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    hover
                    role="complementary"
                    style={{
                      height: 50,
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                    }}
                  >
                    {Array.from({ length: visibleColumns }).map((_, cellIndex) => (
                      <TableCell
                        key={cellIndex}
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      ></TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{ fontSize: '13px', fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', paddingRight: '20px', height: '12%' }}
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={deoOverviewSessions ? deoOverviewSessions['count'] : 0}
            rowsPerPage={deoOverviewSessions?.pageRequest?.size}
            page={deoOverviewSessions?.pageRequest?.page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsChange}
            labelRowsPerPage={
              <h1 style={{ fontSize: '13px', fontWeight: 'bold', marginTop: 20, fontFamily: 'Roboto, sans-serif' }}>Rows per page:</h1>
            }
            labelDisplayedRows={({ from, to, count }) => (
              <div
                data-page={deoOverviewSessions?.pageRequest?.page}
                style={{ fontSize: '13px', fontWeight: 'bold', marginTop: 15, fontFamily: 'Roboto, sans-serif' }}
              >
                {`${from}        -      ${to}        of       ${count}`}
              </div>
            )}
          />
        </div>
      </Grid>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(255, 255, 255, 0.02)', // Lighter color for backdrop
            backdropFilter: 'blur(0.5px)', // Apply the blur effect
          },
        }}
        PaperProps={{
          style: {
            backgroundColor: 'rgba(255, 255, 255, 1)',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Add a subtle box shadow
            border: 'none', // Remove the border
            borderRadius: 8, // Optionally, adjust the border radius
          },
        }}
      >
        <div
          style={{
            width: '450px',
            height: '240px',
            display: 'grid',
            alignItems: 'center',
            justifyItems: 'center',
            padding: '30px',
          }}
        >
          <DialogTitle style={{ color: '#3c6498', fontWeight: 600, display: 'flex', justifyContent: 'center' }}>Confirm Delete</DialogTitle>{' '}
          <DialogContent>
            <DialogContentText style={{ textAlign: 'center', color: '#393939' }}>Are you sure you want to delete?</DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button
              onClick={handleClose}
              style={{
                color: '#000',
                backgroundColor: '#f4f4f4',
                width: '170px',
                height: '40px',
                border: '1px solid #d8d5d5',
              }}
            >
              <img src="../../../content/assets/Group236.png" alt="Icon" style={{ width: '20px', marginRight: 6 }} />
              <span style={{ textTransform: 'capitalize' }}>cancel</span>
            </Button>

            <Button
              onClick={handleDelete}
              style={{
                color: '#FFF',
                backgroundColor: '#D43434',
                width: '170px',
                height: '40px',
                border: '1px solid #c13232',
              }}
              autoFocus
            >
              <img src="../../../content/assets/circle-tick-svgrepo-com.png" alt="Icon" style={{ width: '20px', marginRight: 6 }} />
              <span style={{ textTransform: 'capitalize' }}>yes, delete</span>
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </Grid>
  );
};
export default Overview;
