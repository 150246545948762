/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TablePagination,
  Tooltip,
  Dialog,
  FormHelperText,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { getAllSessionsByPharmacy } from 'app/entities/session/session.reducer';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import useManagePharmacy from '../hooks/useManagePharmay';
import RichDataTable from '../rich-data-table/RichDataTable';
import { Formik, Form } from 'formik';

interface ISessionListParams {
  service_id: any;
  session_status: any;
  session_type: any;
  pageNo: any;
  searchParams: any;
  rows: any;
  q: any;
}

export const AdminManagePharmacy = props => {
  const { states, handlers } = useManagePharmacy();
  const {
    pharmacyIdToDeleted,
    open,
    pageNo,
    modalOpen,
    allServicePharmacist,
    limit,
    totalCount,
    allListPharmacy,
    allPharmacies,
    searchCreteria,
    emptyCellCount,
    emptyRowCount,
    initialState,
    validationSchema,
    allGroupNames,
  } = states;
  const {
    handleModalClose,
    downloadPharmacyInExcelFile,
    handleSearchChange,
    handleRole,
    handleRefresh,
    handledateFrom,
    handledateTill,
    handleChangePage,
    handleChangeRowsPerPage,
    handleClose,
    handleDelete,
    setOpen,
    setPharmacyIdAndOpenStatus,
    handleEdit,
    handlePharmacy,
    onSubmit,
    deletePharmacy,
  } = handlers;

  const navigate = useNavigate();
  return (
    <>
      <Grid
        lg={9.5}
        xs={8}
        container
        style={{ backgroundColor: 'trasnparent', marginTop: 20, marginLeft: 20, maxHeight: '100%', marginBottom: 20 }}
      >
        <Grid lg={12} xs={12} style={{ display: 'flex', marginTop: '2%', marginBottom: '1%', backgroundColor: '' }}>
          <Grid lg={2} xs={2} style={{ backgroundColor: '', width: '100%' }}>
            <Select
              id="demo-simple-select"
              onChange={handleRole}
              value={searchCreteria.group === '' ? 'All Groups' : searchCreteria.group}
              displayEmpty
              style={{
                height: '40px',
                width: '95%',
                fontSize: '12px',
                backgroundColor: '#EFEFEF',
                color: '#393939',
              }}
            >
              <MenuItem value={'All Groups'}>All Groups</MenuItem>
              {allGroupNames.map((item, index) => {
                return (
                  <MenuItem value={item.id} key={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>

          <Grid lg={2} xs={2} style={{ backgroundColor: '', width: '100%' }}>
            <Tooltip title="Date From">
              <div style={{ display: 'flex' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                  <DatePicker
                    className="clock"
                    onChange={newValue => handledateFrom(newValue)}
                    format="DD/MM/YYYY"
                    value={searchCreteria.from === '' ? null : searchCreteria.from}
                    sx={{ marginTop: 0, marginLeft: 0, border: '', width: '95%', height: '80%' }}
                  />
                </LocalizationProvider>
              </div>
            </Tooltip>
          </Grid>

          <Grid lg={2} xs={2} style={{ backgroundColor: '', width: '100%' }}>
            <Tooltip title="Date Till">
              <div style={{ display: 'flex' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                  <DatePicker
                    value={searchCreteria.to === '' ? null : searchCreteria.to}
                    className="clock"
                    onChange={newValue => handledateTill(newValue)}
                    format="DD/MM/YYYY"
                    sx={{ marginTop: 0, marginLeft: 0, border: '', width: '95%', height: '80%' }}
                  />
                </LocalizationProvider>
              </div>
            </Tooltip>
          </Grid>

          <Grid lg={6} xs={6} style={{ backgroundColor: '', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <Tooltip title="Refresh">
              <IconButton
                onClick={() => handleRefresh()}
                style={{
                  backgroundColor: '#7CB6F8',
                  marginLeft: 5,
                  height: 35,
                  width: 35,
                  padding: '0px',
                  justifyContent: 'center',
                  borderRadius: 5,
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                <img src={'../../../../content/assets/refresh.png'} alt="logo" style={{ height: '17px', marginRight: 0, marginLeft: 0 }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Download">
              <IconButton
                onClick={() => downloadPharmacyInExcelFile()}
                style={{
                  backgroundColor: '#7CB6F8',
                  marginRight: 18,
                  marginLeft: 5,
                  height: 35,
                  width: 35,
                  padding: '0px',
                  justifyContent: 'center',
                  borderRadius: 5,
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                <img
                  src={'../../../../content/assets/download2.png'}
                  alt="logo"
                  style={{ height: '17px', marginRight: 0, marginLeft: 0 }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid lg={11.8} xs={12} container style={{ backgroundColor: 'white' }}>
          <Grid lg={12} xs={12} item style={{ height: 30, marginTop: 5 }}>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
              <FormControl sx={{ width: '100%', height: '11%' }}>
                <OutlinedInput
                  style={{ borderRadius: 50, height: '100%', width: '100%' }}
                  placeholder="Search here"
                  onChange={handleSearchChange}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton color="inherit" style={{ height: 30, width: 30 }}>
                        <img
                          src={'../../../../content/assets/search-alt-svgrepo-com (1).png'} // Use the image URL from the array
                          alt="logo"
                          style={{ height: '15px' }}
                        />
                      </IconButton>
                    </InputAdornment>
                  }
                  inputProps={{ style: { fontSize: 13 } }}
                  classes={{ notchedOutline: 'white-outline' }}
                />
              </FormControl>
            </div>
          </Grid>
          <RichDataTable
            limit={limit}
            page={pageNo}
            count={totalCount}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            emptyRowCount={emptyRowCount}
            emptyCellCount={emptyCellCount}
            dataIndexs={['index', 'name', 'gphc_umber', 'contact_number', 'appUsers.internalUser.firstName']}
            dataSource={allListPharmacy}
            headers={['ID', 'Pharmacy', 'Gphc Number', 'Contact Number', 'Assigned to']}
            showEditDeletButton={true}
            handleDelete={setPharmacyIdAndOpenStatus}
            handleEdit={handleEdit}
            handleAssignPharmacy={handlePharmacy}
          />
        </Grid>

        <Dialog
          open={modalOpen}
          onClose={handleModalClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Formik enableReinitialize initialValues={initialState} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => {
              return (
                <Form onSubmit={handleSubmit} noValidate>
                  <div
                    style={{
                      width: 550,
                      height: 300,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      marginTop: '12%',
                    }}
                  >
                    <h1
                      style={{
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        color: '#325C92',
                        marginTop: '10px',
                        textAlign: 'center',
                      }}
                    >
                      Assign Pharmacy
                    </h1>
                    <h1
                      style={{
                        fontSize: '0.8rem',
                        color: 'black',
                        marginTop: '12px',
                        textAlign: 'center',
                      }}
                    >
                      Select a <span style={{ color: '#325C92' }}>Pharmacist</span> to assign to{' '}
                      {/* <span style={{ color: '#325C92' }}> Muhammad Owais Ajmal</span> */}
                    </h1>
                    <div
                      style={{
                        marginTop: '6%',
                        width: '75%',
                        height: '12%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      <Select
                        id="demo-simple-select"
                        defaultValue={0}
                        onChange={handleChange}
                        value={values.userId}
                        displayEmpty
                        name="userId"
                        style={{
                          height: '100%',
                          width: '100%',
                          fontSize: '12px',
                          backgroundColor: '#EFEFEF',
                          color: '#393939',
                        }}
                        error={(touched as any)?.userId && Boolean((errors as any)?.userId)}
                      >
                        <MenuItem value={0}>Select Pharmacist</MenuItem>
                        {allServicePharmacist?.map((elem, index) => {
                          return (
                            <MenuItem value={elem.id} key={elem.id}>
                              {elem.internalUser.firstName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {(touched as any)?.userId && <FormHelperText error={true}>{(errors as any)?.userId}</FormHelperText>}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        marginTop: '8%',
                        marginBottom: '5%',
                        backgroundColor: '',
                        justifyContent: 'center',
                        width: '100%',
                      }}
                    >
                      <IconButton
                        style={{
                          backgroundColor: '#EFEFEF',
                          height: '100%',
                          width: '28%',
                          border: '1px solid lightgray',
                          padding: '10px',
                          display: 'flex',
                          borderRadius: 3,
                          justifyContent: 'center',
                          cursor: 'pointer',
                          transition: 'background-color 0.3s',
                        }}
                        onClick={handleModalClose}
                        // disabled={disabled}
                      >
                        <img
                          src={'../../../../content/assets/cancel2.png'} // Use the image URL from the array
                          alt="logo"
                          style={{ height: '15px' }}
                        />
                        <h1
                          style={{
                            fontSize: 15,
                            marginTop: 8,
                            fontWeight: 400,
                            marginLeft: 10,
                            alignSelf: 'center',
                            justifyContent: 'center',
                            color: 'black',
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          Cancel
                        </h1>
                      </IconButton>

                      <IconButton
                        style={{
                          backgroundColor: '#21AD64',
                          marginLeft: '2%',
                          height: '100%',
                          width: '28%',
                          border: '1px solid lightgray',
                          padding: '10px',
                          display: 'flex',
                          borderRadius: 3,
                          justifyContent: 'center',
                          cursor: 'pointer',
                          transition: 'background-color 0.3s',
                        }}
                        type="submit"
                        // onClick={handleSessionSubmit}
                      >
                        <img
                          src={'../../../../content/assets/in-progress-svgrepo-com.png'} // Use the image URL from the array
                          alt="logo"
                          style={{ height: '15px' }}
                        />
                        <h1
                          style={{
                            fontSize: 15,
                            marginTop: 8,
                            fontWeight: 400,
                            marginLeft: 10,
                            alignSelf: 'center',
                            justifyContent: 'center',
                            color: 'white',
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          Done
                        </h1>
                      </IconButton>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Dialog>
      </Grid>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to delete.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={() => deletePharmacy()} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default AdminManagePharmacy;
