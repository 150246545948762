import React, { useCallback, useEffect, useState } from 'react';
import Overview from './overview';
import Overdue from './overdue';
import Uploaded from './uploaded';
import File from './file';
import Record from './record';
import ManagePharmacy from './managePharmacy';
import { DashboardMenuCommp } from 'app/modules/deo-work-flow/deo-side-menu/dashboard-menu-commp';
import DailySummary from 'app/modules/deo-work-flow/deo-listing-component/daily-summary';
import {
  getAllDeoSessionsCurrentDateStats,
  getRecordsStatsForDeo,
  permanentDeleteUnSubmittedFiles,
} from 'app/modules/deo-work-flow/deo-reducer';
import { useAppDispatch } from 'app/config/store';
import { IDeoSessionStatsParam } from 'app/modules/deo-work-flow/interface';

type PageProps = {
  id: string;
};
const intialState: IDeoSessionStatsParam = {
  pharmacist: '',
  pharmacy: '',
  branch: '',
  group: '',
};

const Page: React.FC<PageProps> = ({ id }) => {
  const file_id = localStorage.getItem('fileId');
  const [selectedContent, setSelectedContent] = React.useState('Daily-Summary');
  const [searchCreteria, setsearchCreteria] = useState<IDeoSessionStatsParam>(intialState);
  const dispatch = useAppDispatch();
  const getRecordsStats = useCallback(() => {
    dispatch(getRecordsStatsForDeo());
  }, []);

  const getAllDeoSessionStatsCurrentDate = useCallback(() => {
    dispatch(getAllDeoSessionsCurrentDateStats(searchCreteria));
  }, []);
  useEffect(() => {
    localStorage.setItem('pharmacyName', '');
    localStorage.setItem('pharmacyNumber', '');
    localStorage.setItem('uploadedFileName', '');
    localStorage.setItem('uploadedFileCount', '');
    localStorage.setItem('uploadedFileDeleteCount', '');
    localStorage.setItem('pharmacySelected', '');
    localStorage.setItem('pharmacy_rule', '');
    localStorage.setItem('serviceSelected', '');

    if (file_id !== '') {
      deleteNotSubmittedUploadedFile(file_id)
        .then(() => {
          localStorage.setItem('fileId', '');
          // getRecordsStats();
          // getAllDeoSessionStatsCurrentDate();
        })
        .catch(error => {
          console.error('Error deleting or fetching uploaded files:', error);
        });
    }

    localStorage.setItem('fileName', '');
  }, []);
  const deleteNotSubmittedUploadedFile = async (fID: any): Promise<void> => {
    await dispatch(permanentDeleteUnSubmittedFiles(fID));
    // Optionally, return something if needed
  };
  const renderContent = () => {
    switch (selectedContent) {
      case 'Daily-Summary':
        return <DailySummary />;
      case 'Overview':
        return <Overview />;
      case 'Overdue':
        return <Overdue />;
      case 'Uploaded':
        return <Uploaded />;
      case 'ManagePharmacy':
        return <ManagePharmacy />;
      case 'File':
        return <File />;
      case 'Record':
        return <Record />;

      default:
        return null;
    }
  };

  return (
    <>
      <DashboardMenuCommp
        onSelectContent={content => setSelectedContent(content)}
        sessionCounts={{ interventionCount: 0, followUpCount: 0, overdueCount: 0 }}
      />

      <div style={{ width: '80vw', height: '92vh' }}>{renderContent()}</div>
    </>
  );
};

export default Page;
