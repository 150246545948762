import React from 'react';
import {
  Grid,
  IconButton,
  FormControl,
  OutlinedInput,
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import ManagePharmacyHook from '../hooks/managePharmacyHook';
import { ActionsFilter, DateFilter } from '../filter/filter';
import moment from 'moment';

export const ManagePharmacy = props => {
  const { states, handlers } = ManagePharmacyHook();
  const { emptyCellCount, emptyRowCount, searchCreteria, deoManagePharmacyFiles, open } = states;
  const {
    handlePageChange,
    handleRowsChange,
    handleClose,
    handleEdit,
    handleSearchChange,
    handledateFrom,
    handledateTill,
    getDownloadData,
  } = handlers;

  return (
    <Grid
      lg={12}
      xs={12}
      container
      style={{ backgroundColor: 'transparent', position: 'sticky', top: 0, marginTop: 20, marginLeft: 20, height: '87%' }}
    >
      <Grid lg={11.6} xs={11.6} style={{ display: 'flex', marginTop: '20px', justifyContent: 'space-between' }}>
        <DateFilter handledateFrom={handledateFrom} handledateTill={handledateTill} searchCreteria={searchCreteria} />

        <ActionsFilter getDownloadData={getDownloadData} />
      </Grid>

      <Grid
        lg={11.6}
        xs={11.6}
        container
        style={{ marginTop: 20, backgroundColor: 'white', height: '94%', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}
      >
        <div style={{ backgroundColor: 'white', justifyContent: 'center', flex: 1, height: '100%', alignItems: 'center', width: '98%' }}>
          <FormControl sx={{ width: '100%', height: '11%' }}>
            <OutlinedInput
              style={{ borderRadius: 50, height: '100%', width: '100%' }}
              placeholder="Search here"
              onChange={handleSearchChange}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton color="inherit" style={{ height: 30, width: 30 }}>
                    <img
                      src={'../../../../content/assets/search-alt-svgrepo-com (1).png'} // Use the image URL from the array
                      alt="logo"
                      style={{ height: '15px' }}
                    />
                  </IconButton>
                </InputAdornment>
              }
              inputProps={{ style: { fontSize: 13 } }}
              classes={{ notchedOutline: 'white-outline' }}
            />
          </FormControl>

          <TableContainer sx={{ height: '77%' }}>
            <Table stickyHeader aria-label="sticky table" style={{ border: 'none' }}>
              <TableHead sx={{ border: 'none' }}>
                <TableRow sx={{ border: 'none' }}>
                  <TableCell
                    sx={{
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      fontFamily: 'Roboto, sans-serif',
                      paddingLeft: '40px',
                    }}
                  >
                    ID
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      fontFamily: 'Roboto, sans-serif',
                      paddingLeft: '40px',
                    }}
                  >
                    Pharmacy Name
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      fontFamily: 'Roboto, sans-serif',
                      paddingLeft: '40px',
                    }}
                  >
                    Address
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      fontFamily: 'Roboto, sans-serif',
                      paddingLeft: '40px',
                    }}
                  >
                    Contact Number
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      fontFamily: 'Roboto, sans-serif',
                      paddingLeft: '40px',
                    }}
                  >
                    Gphc Number
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '10%',
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {deoManagePharmacyFiles?.results &&
                  deoManagePharmacyFiles.results.map((item, index) => (
                    <TableRow key={index} hover role="complementary" style={{ height: 50 }}>
                      <TableCell
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                          paddingLeft: '40px',
                        }}
                      >
                        {Number(index) + Number(1)}
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                          paddingLeft: '40px',
                        }}
                      >
                        {item?.name}
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                          paddingLeft: '40px',
                        }}
                      >
                        {item?.address}
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                          paddingLeft: '40px',
                        }}
                      >
                        {item?.contact_number}
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                          paddingLeft: '40px',
                        }}
                      >
                        {item?.gphc_umber}
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={() => handleEdit(item?.id)}
                              style={{
                                backgroundColor: '',
                                marginLeft: 0,
                                height: 25,
                                width: 25,
                                padding: '10px',
                                display: 'flex',
                                borderRadius: 100,
                                cursor: 'pointer',
                                transition: 'background-color 0.3s',
                              }}
                            >
                              <img
                                src={'../../../../content/assets/Edit.png'}
                                alt="logo"
                                style={{ height: '20px', marginRight: 0, marginLeft: 0 }}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                {Array.from({ length: emptyRowCount }).map((_, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    hover
                    role="complementary"
                    style={{
                      height: 50,
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                    }}
                  >
                    {Array.from({ length: emptyCellCount }).map((_, cellIndex) => (
                      <TableCell
                        key={cellIndex}
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      ></TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{ height: '12%', fontSize: '13px', fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', paddingRight: '20px' }}
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={deoManagePharmacyFiles ? deoManagePharmacyFiles['count'] : 0}
            rowsPerPage={deoManagePharmacyFiles?.pageRequest?.size}
            page={deoManagePharmacyFiles?.pageRequest?.page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsChange}
            labelRowsPerPage={
              <h1 style={{ fontSize: '13px', fontWeight: 'bold', marginTop: 20, fontFamily: 'Roboto, sans-serif' }}>Rows per page:</h1>
            }
            labelDisplayedRows={({ from, to, count }) => (
              <div
                data-page={deoManagePharmacyFiles?.pageRequest?.page}
                style={{ fontSize: '13px', fontWeight: 'bold', marginTop: 15, fontFamily: 'Roboto, sans-serif' }}
              >
                {`${from}        -      ${to}        of       ${count}`}
              </div>
            )}
          />
        </div>
      </Grid>
    </Grid>
  );
};
export default ManagePharmacy;
