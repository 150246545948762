import './dashboard-menu-commp.scss';
import React, { useEffect, useState } from 'react';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import { listItemDecoratorClasses } from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemButton from '@mui/joy/ListItemButton';
import Typography from '@mui/joy/Typography';
import { Divider, Grid, IconButton, Menu, MenuItem } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { logout } from 'app/shared/reducers/authentication';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllSessionsByPharmacy } from 'app/entities/session/session.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

type dashboardMenuCommpProps = {
  onSelectContent: (content: string) => void;
  sessionCounts: any;
};

export const DashboardMenuCommp = ({ onSelectContent, sessionCounts }: dashboardMenuCommpProps) => {
  const role = JSON.parse(localStorage.getItem('role'));
  const [showMenu] = React.useState(false);
  const [index, setIndex] = React.useState(0);
  const [showOptions, setShowOptions] = React.useState(false);
  const [selectedColor, setSelectedColor] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = useAppSelector(state => state.authentication.account);
  const overdueListingCount = useAppSelector(state => state.deo.deoOverdueSessionCount);
  const deoUploadedFileCount = useAppSelector(state => state.deo.deoUploadedFileCount);
  const deoManagePharmacyFileCount = useAppSelector(state => state.deo.deoManagePharmacyFileCount);
  const deoRecordCount = useAppSelector(state => state.deo.deoRecordCount);
  const deoFileCount = useAppSelector(state => state.deo.deoFileCount);
  const isDEO = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.DEO])) && role === 'DEO';
  const isLeadDEO =
    useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.LEAD_DEO])) && role === 'LEAD_DEO';
  const [activeItem, setActiveItem] = useState('Daily-Summary');
  const { state }: any = useLocation();

  const handleItemClick = (content: string) => {
    onSelectContent(content);
    setActiveItem(content);
  };
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    handleClose();
    navigate('/login');
  };

  useEffect(() => {
    if (state && state.activeItem) {
      handleItemClick(state.activeItem);
    }
  }, [state]);

  return (
    <Grid
      lg={2.3}
      xs={4}
      sm={4}
      container
      style={{ height: '92.3vh', backgroundColor: '#325C92', justifyContent: 'center', position: 'relative' }}
    >
      {showMenu === false ? (
        <Grid lg={10} xs={10} sm={10} style={{ marginTop: 30, height: '50%', backgroundColor: '' }}>
          <List
            aria-label="Sidebar"
            sx={{
              '--ListItem-paddingLeft': '0px',
              '--ListItemDecorator-size': '20px',
              '--ListItem-minHeight': '10px',
              '--List-nestedInsetStart': '10px',
              [`& .${listItemDecoratorClasses.root}`]: {
                justifyContent: 'space-between',
                pr: '18px',
              },
              '& [role="button"]': {
                borderRadius: '0 2px 2px 0',
              },
              height: 300,
            }}
          >
            <ListItem>
              <ListItemButton
                onClick={() => handleItemClick('Daily-Summary')}
                style={{
                  backgroundColor: activeItem === 'Daily-Summary' ? '#7CB6F8' : 'transparent',
                  cursor: 'pointer',
                  borderRadius: 1,
                  height: 38,
                }}
              >
                <img
                  src={'../../../../content/assets/in-progress-svgrepo-com.png'}
                  alt="logo"
                  style={{ height: '17px', marginRight: 10, marginLeft: 20 }}
                />
                <ListItemContent sx={{ fontWeight: 300, color: 'white', fontSize: 14, marginLeft: 1, fontFamily: 'Roboto, sans-serif' }}>
                  Daily Summary
                </ListItemContent>
              </ListItemButton>
            </ListItem>
            {!isDEO && (
              <>
                <Divider style={{ backgroundColor: 'lightgray', height: 1 }} />
                <ListItem>
                  <ListItemButton
                    onClick={() => handleItemClick('Overview')}
                    style={{
                      backgroundColor: activeItem === 'Overview' ? '#7CB6F8' : 'transparent',
                      cursor: 'pointer',
                      borderRadius: 1,
                      height: 38,
                    }}
                  >
                    <img
                      src={'../../../../content/assets/in-progress-svgrepo-com.png'}
                      alt="logo"
                      style={{ height: '17px', marginRight: 10, marginLeft: 20 }}
                    />
                    <ListItemContent
                      sx={{ fontWeight: 300, color: 'white', fontSize: 14, marginLeft: 1, fontFamily: 'Roboto, sans-serif' }}
                    >
                      Overview
                    </ListItemContent>
                  </ListItemButton>
                </ListItem>

                {/*<Divider style={{ backgroundColor: 'lightgray', height: 1 }} />*/}
                {/*<ListItem>*/}
                {/*  <ListItemButton*/}
                {/*    onClick={() => handleItemClick('Overdue')}*/}
                {/*    style={{*/}
                {/*      backgroundColor: activeItem === 'Overdue' ? '#7CB6F8' : 'transparent',*/}
                {/*      cursor: 'pointer',*/}
                {/*      borderRadius: 1,*/}
                {/*      height: 38,*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    <img*/}
                {/*      src={'../../../../content/assets/Group 24.png'}*/}
                {/*      alt="logo"*/}
                {/*      style={{ height: '17px', marginRight: 10, marginLeft: 20 }}*/}
                {/*    />*/}
                {/*    <ListItemContent*/}
                {/*      sx={{ fontWeight: 300, color: 'white', fontSize: 14, marginLeft: 1, fontFamily: 'Roboto, sans-serif' }}*/}
                {/*    >*/}
                {/*      Overdue*/}
                {/*    </ListItemContent>*/}
                {/*    <Typography level="body-sm" sx={{ fontWeight: 'normal', color: 'white', fontSize: 13 }}>*/}
                {/*      {overdueListingCount || 0}*/}
                {/*    </Typography>*/}
                {/*  </ListItemButton>*/}
                {/*</ListItem>*/}

                <Divider style={{ backgroundColor: 'lightgray', height: 1 }} />
                <ListItem>
                  <ListItemButton
                    onClick={() => handleItemClick('Uploaded')}
                    style={{
                      backgroundColor: activeItem === 'Uploaded' ? '#7CB6F8' : 'transparent',
                      cursor: 'pointer',
                      borderRadius: 1,
                      height: 38,
                    }}
                  >
                    <img
                      src={'../../../../content/assets/circle-tick-svgrepo-com.png'}
                      alt="logo"
                      style={{ height: '17px', marginRight: 10, marginLeft: 20 }}
                    />
                    <ListItemContent
                      sx={{ fontWeight: 300, color: 'white', fontSize: 14, marginLeft: 1, fontFamily: 'Roboto, sans-serif' }}
                    >
                      Uploaded
                    </ListItemContent>
                    <Typography level="body-sm" sx={{ fontWeight: 'normal', color: 'white', fontSize: 13 }}>
                      {deoUploadedFileCount || 0}
                    </Typography>
                  </ListItemButton>
                </ListItem>
                {!isLeadDEO && (
                  <>
                    <Divider style={{ backgroundColor: 'lightgray', height: 1 }} />
                    <ListItem>
                      <ListItemButton
                        onClick={() => handleItemClick('ManagePharmacy')}
                        style={{
                          backgroundColor: activeItem === 'ManagePharmacy' ? '#7CB6F8' : 'transparent',
                          cursor: 'pointer',
                          borderRadius: 1,
                          height: 38,
                        }}
                      >
                        <img
                          src={'../../../../content/assets/circle-tick-svgrepo-com.png'}
                          alt="logo"
                          style={{ height: '17px', marginRight: 10, marginLeft: 20 }}
                        />
                        <ListItemContent
                          sx={{ fontWeight: 300, color: 'white', fontSize: 14, marginLeft: 1, fontFamily: 'Roboto, sans-serif' }}
                        >
                          Manage Branches
                        </ListItemContent>
                        <Typography level="body-sm" sx={{ fontWeight: 'normal', color: 'white', fontSize: 13 }}>
                          {deoManagePharmacyFileCount || 0}
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                    <Divider style={{ backgroundColor: 'lightgray', height: 1 }} />
                    <ListItem>
                      <ListItemButton
                        onClick={() => {
                          setShowOptions(prevState => !prevState);
                          handleItemClick('File');
                        }}
                        style={{
                          backgroundColor: activeItem === 'File' || activeItem === 'Record' ? '#7CB6F8' : 'transparent',
                          cursor: 'pointer',
                          borderRadius: 1,
                          height: 38,
                        }}
                      >
                        <img
                          src={'../../../../content/assets/no-svgrepo-com.png'}
                          alt="logo"
                          style={{ height: '17px', marginRight: 10, marginLeft: 20 }}
                        />

                        <ListItemContent
                          sx={{ fontWeight: 300, color: 'white', fontSize: 14, marginLeft: 1, fontFamily: 'Roboto, sans-serif' }}
                        >
                          Trash
                        </ListItemContent>
                        {showOptions === true ? (
                          <img src={'../../../../content/assets/uparrow.png'} alt="logo" style={{ height: '13px', marginLeft: 24 }} />
                        ) : (
                          <Typography level="body-sm" sx={{ fontWeight: 'normal', color: 'white', fontSize: 13 }}>
                            {deoFileCount + deoRecordCount}
                          </Typography>
                        )}
                      </ListItemButton>
                    </ListItem>
                    {showOptions === true ? (
                      <>
                        <Divider style={{ backgroundColor: '#325C92', height: 3 }} />
                        <ListItem>
                          <ListItemButton
                            selected={index === 1}
                            style={{
                              backgroundColor: activeItem === 'File' ? '#4877B1' : 'transparent',
                              cursor: 'pointer',
                              borderRadius: 1,
                              height: 38,
                            }}
                            onClick={() => {
                              handleItemClick('File');
                            }}
                          >
                            <ListItemContent
                              sx={{
                                fontWeight: 'normal',
                                color: selectedColor === 'four' ? '#4877B1' : undefined,
                                fontSize: 13,
                                marginLeft: 7,
                                height: 23,
                                marginTop: 0,
                                fontFamily: 'Roboto, sans-serif',
                              }}
                            >
                              <span
                                style={{ color: 'white' }}
                                // data-session-type={NMS_TYPES.INTERVENTION}
                                // data-index={1}
                                // data-session-status={NMS_STATUS.IN_PROGRESS}
                              >
                                - File
                              </span>
                            </ListItemContent>
                            <Typography level="body-sm" sx={{ fontWeight: 'normal', color: 'white', fontSize: 13 }}>
                              {deoFileCount || 0}
                            </Typography>
                          </ListItemButton>
                        </ListItem>

                        <Divider style={{ backgroundColor: '#325C92', height: 3 }} />

                        <ListItem
                        // onClick={handleSessionListing}
                        // data-session-type={NMS_TYPES.FOLLOW_UP}
                        // data-session-status={NMS_STATUS.IN_PROGRESS}
                        >
                          <ListItemButton
                            // data-session-type={NMS_TYPES.FOLLOW_UP}
                            // data-index={2}
                            // data-session-status={NMS_STATUS.IN_PROGRESS}
                            selected={index === 2}
                            style={{
                              backgroundColor: activeItem === 'Record' ? '#4877B1' : 'transparent',
                              cursor: 'pointer',
                              borderRadius: 1,
                              height: 38,
                            }}
                            onClick={() => {
                              handleItemClick('Record');
                            }}
                          >
                            <ListItemContent
                              // data-session-type={NMS_TYPES.FOLLOW_UP}
                              // data-index={2}
                              // data-session-status={NMS_STATUS.IN_PROGRESS}
                              sx={{
                                fontWeight: 'normal',
                                color: selectedColor === 'two' ? '#4877B1' : undefined,
                                fontSize: 13,
                                marginLeft: 7,
                                height: 22,
                                marginTop: 0,
                                fontFamily: 'Roboto, sans-serif',
                              }}
                            >
                              <span
                                style={{ color: 'white' }}
                                // data-session-type={NMS_TYPES.FOLLOW_UP}
                                // data-index={2}
                                // data-session-status={NMS_STATUS.IN_PROGRESS}
                              >
                                - Records
                              </span>
                            </ListItemContent>
                            <Typography level="body-sm" sx={{ fontWeight: 'normal', color: 'white', fontSize: 13 }}>
                              {deoRecordCount || 0}
                            </Typography>
                          </ListItemButton>
                        </ListItem>

                        <Divider style={{ color: '#325C92', height: 3 }} />
                      </>
                    ) : null}
                  </>
                )}
              </>
            )}
          </List>

          <div className="logout">
            <img src={'../../../../content/assets/profile1.png'} alt="logo" style={{ height: '35px' }} onClick={() => {}} />

            <div
              style={{
                justifyContent: 'space-between',
                marginLeft: 5,
                width: '70%',
                overflow: 'hidden',
                alignSelf: 'center',
                marginTop: 8,
              }}
            >
              <div>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
              <h1 className="logout_text"> {role === null || role === 'null' ? '' : role.replace(/"/g, '').replace(/_/g, ' ')}</h1>
              <h1 className="logout_text">{currentUser && currentUser['login']}</h1>
              <h1 style={{ fontSize: 10, color: 'white', marginLeft: 0.5, fontFamily: 'Roboto, sans-serif', marginTop: -3 }}></h1>
              <h1 style={{ fontSize: 10, color: 'white', marginLeft: 0.5, fontFamily: 'Roboto, sans-serif', marginTop: -3 }}></h1>
            </div>
            <IconButton
              onClick={handleClick}
              style={{
                backgroundColor: '',
                marginRight: 5,
                height: 30,
                width: 20,
                padding: '0px',
                display: 'flex',
                borderRadius: 5,
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
            >
              <img src={'../../../../content/assets/threedots.png'} alt="logo" style={{ height: '25px' }} />
            </IconButton>
          </div>
        </Grid>
      ) : (
        []
      )}
    </Grid>
  );
};
