import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  Box,
  Checkbox,
  Button,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Modal,
  InputAdornment,
  FormHelperText,
  FormControl,
} from '@mui/material';
import './set-password.scss';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useAppDispatch } from 'app/config/store';
import { forgotUserPassword, setUserPassword } from 'app/shared/reducers/authentication';
import { toast } from 'react-toastify';

const ForgotPasswordModal = props => {
  const [initialState, setInitalState] = useState({ username: '' });
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const validationSchema = () => {
    return yup.object().shape({
      username: yup.string().required('Username is required'),
    });
  };

  const onSubmit = useCallback(async params => {
    const { username } = params;
    const data = {
      username,
    };
    const res = await dispatch(forgotUserPassword(data));
    if (res.type === 'authentication/forgot_password/fulfilled') {
      toast.success('Password reset link sent to your email please check.');
      navigate('/login');
    }
  }, []);

  return (
    <Box>
      <Container component="main" maxWidth="sm" style={{ marginBottom: 20 }}>
        <Box
          sx={{
            boxShadow: 3,
            borderRadius: 2,
            px: 8,
            py: 6,
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{ mb: 3 }}>
            <img src={'../../../content/logos/pharmacy-solution-logo.png'} className="login-logo" />
          </Box>
          <Typography component="h1" fontWeight={'600'} variant="h6">
            Change your Password
          </Typography>
          <Formik enableReinitialize initialValues={initialState} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => {
              return (
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
                  <TextField
                    margin="normal"
                    required
                    autoFocus
                    fullWidth
                    type={'text'}
                    id="username"
                    label="Username"
                    name="username"
                    value={values.password}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={touched.username && Boolean(errors.username)}
                  />
                  {touched.username && <FormHelperText error={true}>{(errors as any).username}</FormHelperText>}
                  <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2, height: 40 }} type={'submit'}>
                    Submit
                  </Button>
                </Box>
              );
            }}
          </Formik>
        </Box>
      </Container>
    </Box>
  );
};

export default ForgotPasswordModal;
