import { useAppDispatch, useAppSelector } from 'app/config/store';
import { isValidDate, s2ab } from 'app/shared/util/utits';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import * as yup from 'yup';
import {
  assignPharmacy,
  deletePharmacyById,
  deleteUserwithRoles,
  downloadAllPharmacies,
  getAllServicePharmacists,
  getPharmacies,
  listAllPharmacy,
  getGroupNames,
} from '../admin-reducer';
import { IAssignPharmacy, IListingPharmacy } from '../interface';

const intialState: IListingPharmacy = {
  size: 20,
  page: 0,
  searchQuery: '',
  pharmacy: '',
  from: '',
  to: '',
  group: '',
};

const initialState = {
  userId: 0,
};

const useManagePharmacy = () => {
  const pageNo = useAppSelector(state => state.admin.pharmacyPage);
  const limit = useAppSelector(state => state.admin.pharmacyRows);
  const totalCount = useAppSelector(state => state.admin.pharmacyCount);
  const allServicePharmacist = useAppSelector(state => state.admin.listAllServicePharmacist);

  const [pharmacyId, setPharmacyID] = useState(0);

  const [modalOpen, setModalOpen] = useState(false);

  const allPharmacies = useAppSelector(state => state.admin.allListPharmacies);
  const allPharmacy = useAppSelector(state => state.admin.listPharmacy);
  const allGroupNames = useAppSelector(state => state.admin.allGroupNames);

  const [searchCreteria, setsearchCreteria] = useState<IListingPharmacy>(intialState);
  const [open, setOpen] = useState(false);
  const [pharmacyIdToDeleted, setPharmacyIdToBeDeleted] = useState();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const emptyRowCount = 10;
  const emptyCellCount = 7;

  const allListPharmacy = useMemo(() => {
    return allPharmacy.map((elem, index) => {
      const { appUsers } = elem;
      const manipulatedAppUsers = appUsers.length
        ? appUsers.filter(appUser => appUser.internalUser?.authorities?.[0]?.name !== 'CLIENT')
        : [];

      return {
        ...elem,
        index: index + 1,
        appUsers: manipulatedAppUsers,
      };
    });
  }, [allPharmacy]);

  const validationSchema = () => {
    return yup.object().shape({
      userId: yup.number().required('userID is a required field.').moreThan(0, 'Please Choose Pharmacy'),
    });
  };

  const setPharmacyIdAndOpenStatus = useCallback(id => {
    setPharmacyIdToBeDeleted(id);
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const onSubmit = useCallback(
    async params => {
      const data: IAssignPharmacy = {
        userId: String(params.userId),
        pharmacyId: String(pharmacyId),
      };
      const res = await dispatch(assignPharmacy(data));

      if (res.type === 'manage_pharmacy/assign_pharmacy/fulfilled') {
        toast.success('Pharmacy assigned successfully');
        setModalOpen(false);
        await dispatch(listAllPharmacy(searchCreteria));
      }
    },
    [searchCreteria, pharmacyId]
  );

  const deletePharmacy = useCallback(async () => {
    const res = await dispatch(deletePharmacyById(Number(pharmacyIdToDeleted)));
    if (res.type === 'manage_pharmacy/delete_pharmacy/fulfilled') {
      toast.success('Pharmacy deleted successfully');
      navigate('/admin/dashboard', { state: 'four' });
    }
    listAllRolesWithDateRange();

    setOpen(false);
  }, [pharmacyIdToDeleted]);

  const handlePharmacy = useCallback(
    (pharmacy: number) => {
      setPharmacyID(Number(pharmacy));
      setModalOpen(true);

      if (allServicePharmacist.length <= 0) {
        dispatch(getAllServicePharmacists());
      }
    },
    [allServicePharmacist]
  );

  const handleEdit = useCallback(item => {
    navigate(`/admin/edit-pharmacy/${item.id}`);
    localStorage.setItem('lastActiveTabAdmin', JSON.stringify({ selectedColor: 'five', index: 5 }));
  }, []);

  const handleDelete = useCallback(async id => {
    const res = await dispatch(deleteUserwithRoles(id));
    if (res.type === 'manage_roles/delete_users/fulfilled') {
      toast.success('User deleted successfully');
      listAllRolesWithDateRange();
      setOpen(false);
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    const params = {
      ...searchCreteria,
      page: newPage,
    };
    setsearchCreteria({
      ...searchCreteria,
      page: newPage,
    });
    dispatch(listAllPharmacy(params));
  };

  const handleChangeRowsPerPage = event => {
    const pagePerRow = event.target.value;
    const params = {
      ...searchCreteria,
      page: 0,
      size: pagePerRow,
    };
    setsearchCreteria({
      ...searchCreteria,
      page: 0,
      size: pagePerRow,
    });
    dispatch(listAllPharmacy(params));
  };

  // Your debounce function
  const debounce = useCallback((func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args); // Pass the arguments to the debounced function
      }, delay);
    };
  }, []);

  // Your search function
  const handleSearch = value => {
    // You can also perform your API call here with the value
    const params = {
      ...searchCreteria,
      searchQuery: value,
    };

    dispatch(listAllPharmacy(params));
  };

  const debouncedSearch = debounce(handleSearch, 1500);

  const handleSearchChange = event => {
    const { value } = event.target;
    setsearchCreteria({
      ...searchCreteria,
      searchQuery: value,
    });
    debouncedSearch(value); // Pass the input value to debouncedSearch
  };

  const handledateFrom = e => {
    const date = isValidDate(e);

    const params = {
      ...searchCreteria,
      from: date,
    };
    setsearchCreteria({
      ...searchCreteria,
      from: date,
    });
    dispatch(listAllPharmacy(params));
  };
  const handledateTill = e => {
    const date = isValidDate(e);

    const params = {
      ...searchCreteria,
      to: date,
    };
    setsearchCreteria({
      ...searchCreteria,
      to: date,
    });
    dispatch(listAllPharmacy(params));
  };

  const handleRole = event => {
    let value = event.target.value;
    value = value === 'All Groups' ? '' : value;
    const params = {
      ...searchCreteria,
      group: value,
    };
    setsearchCreteria({
      ...searchCreteria,
      group: value,
    });
    dispatch(listAllPharmacy(params));
  };

  const handleRefresh = useCallback(() => {
    const params = {
      ...intialState,
    };

    setsearchCreteria({
      ...intialState,
      pharmacy: '',
      from: '',
      to: '',
      searchQuery: '',
    });

    dispatch(listAllPharmacy(params));
  }, []);

  const downloadPharmacyInExcelFile = useCallback(async () => {
    const res: any = await dispatch(downloadAllPharmacies(searchCreteria));

    if (res.type === 'manage_pharmacy/download_all_pharmacy/fulfilled') {
      const rolesRecord = res.payload.data.results;
      const data = rolesRecord.map(elem => {
        const { appUsers } = elem;
        const user = appUsers.length ? appUsers.filter(appUser => appUser.internalUser?.authorities?.[0]?.name !== 'CLIENT') : [];
        const userName = user?.length ? user?.[0]?.internalUser?.firstName : 'Not Assigned Yet';
        const userEmail = user?.length ? user?.[0]?.internalUser?.email : 'Not Assigned Yet';

        return {
          'Pharmacy Name': elem.name,
          'Gphc Number': elem.gphc_umber,
          'Pharmacy Contact No.': elem.contact_number,
          'Assigned To (Name)': userName,
          'Assigned To (Email)': userEmail,
        };
      });

      // Create a worksheet
      const ws = XLSX.utils.json_to_sheet(data);
      const totalColumns = XLSX.utils.decode_range(ws['!ref']).e.c + 1;
      ws['!cols'] = Array.from({ length: totalColumns }, (_, index) => {
        const columnTextLengths = [];
        for (let R = 0; R <= XLSX.utils.decode_range(ws['!ref']).e.r; R++) {
          const cellAddress = { c: index, r: R };
          const cellRef = XLSX.utils.encode_cell(cellAddress);
          const cellText = ws[cellRef]?.v?.toString() ?? '';
          columnTextLengths.push(cellText.length);
        }
        const maxTextLength = Math.max(...columnTextLengths);
        return { wch: maxTextLength + 2 }; // Adding extra space for better readability
      });

      // Create a workbook with the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'pharmacies');

      // Generate XLSX data in binary form
      const xlsxData = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

      // Convert the binary data to a Blob
      const blob = new Blob([s2ab(xlsxData)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a download link for the Excel file
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Pharmacy List.xlsx'; // Set the file name here
      document.body.appendChild(a);
      a.click();

      // Clean up the URL object
      URL.revokeObjectURL(url);
    }
  }, [searchCreteria]);

  const fetchAllPharmacies = useCallback(() => {
    dispatch(getPharmacies());
  }, []);

  const listAllRolesWithDateRange = useCallback(() => {
    dispatch(listAllPharmacy(searchCreteria));
    dispatch(getGroupNames());
  }, []);

  useEffect(() => {
    fetchAllPharmacies();
    listAllRolesWithDateRange();
  }, []);

  const states = {
    pageNo,
    limit,
    totalCount,
    allListPharmacy,
    allPharmacies,
    searchCreteria,
    emptyCellCount,
    emptyRowCount,
    open,
    pharmacyIdToDeleted,
    modalOpen,
    allServicePharmacist,
    initialState,
    validationSchema,
    allGroupNames,
  };
  const handlers = {
    handleSearchChange,
    handleRole,
    handleRefresh,
    handledateFrom,
    handledateTill,
    handleChangePage,
    handleChangeRowsPerPage,
    downloadPharmacyInExcelFile,
    handleClose,
    handleDelete,
    setOpen,
    setPharmacyIdAndOpenStatus,
    deletePharmacy,
    handleEdit,
    handlePharmacy,
    handleModalClose,
    onSubmit,
  };

  return {
    states,
    handlers,
  };
};

export default useManagePharmacy;
