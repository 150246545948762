import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Button,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import './login.scss';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Navigate, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { logout, setCurrentUserRole } from 'app/shared/reducers/authentication';
import { deleteUserwithRoles } from 'app/modules/admin-work-flow/admin-reducer';
import { toast } from 'react-toastify';

const RoleModal = props => {
  const dispatch = useAppDispatch();
  const { account } = useAppSelector(state => state.authentication);
  const [roleArray, setRoleArray] = useState([]);
  const [value, setValue] = useState('');
  const navigate = useNavigate();

  const { isAuthenticated, isPharmacist, loginError, showModalLogin, isDEO, isLeadDEO, isManagerDEO, isAdmin, isClient } = useAppSelector(
    state => state.authentication
  );

  useEffect(() => {
    setRoleArray(account?.authorities);
  }, [account]);

  const handleSelectedRole = event => {
    setValue(event.target.value);
  };
  const logoutUser = () => {
    dispatch(logout());
    navigate('/login');
  };
  const handleRole = () => {
    if (value !== 'Select Role') {
      dispatch(setCurrentUserRole(value));
    } else {
      dispatch(setCurrentUserRole(null));
    }
    if (isAuthenticated && (isDEO || isLeadDEO || isManagerDEO) && (value === 'DEO' || value === 'LEAD_DEO' || value === 'MANAGER_DEO')) {
      navigate('/operator/dashboard');
    }
    if (isAuthenticated && isPharmacist && value === 'PHARMACIST') {
      navigate('/login/pharmacy');
    }

    if (isAuthenticated && isAdmin && value === 'ROLE_ADMIN') {
      navigate('/admin/dashboard');
    }

    if (isAuthenticated && isClient && value === 'CLIENT') {
      navigate('/client/dashboard');
    }
  };
  const formatRole = elem => {
    if (elem === 'ROLE_ADMIN') {
      return 'ADMIN';
    } else if (elem === 'ROLE_USER') {
      return 'USER';
    } else if (elem === 'MANAGER_DEO') {
      return 'MANAGER DEO';
    } else if (elem === 'LEAD_DEO') {
      return 'LEAD DEO';
    } else if (elem === 'CLIENT') {
      return 'PHARMACY CONTRACTOR';
    } else {
      return elem;
    }
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleRole();
    }
  };

  return (
    <Box>
      <Container component="main" style={{ width: 500, height: '40%', marginBottom: 20 }}>
        <Box
          sx={{
            boxShadow: 3,
            borderRadius: 2,
            px: 8,
            py: 6,
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Box style={{ position: 'absolute', top: 0, left: 0, padding: '10px', cursor: 'pointer' }}>
            <ArrowBackIcon onClick={logoutUser} />
          </Box>
          <Box sx={{ mb: 3 }}>
            <img src={'../../../content/logos/pharmacy-solution-logo.png'} className="login-logo" />
          </Box>

          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              mt: 2,
            }}
          >
            <Grid item onKeyDown={handleKeyDown}>
              <FormControl>
                <Select
                  id="demo-simple-select"
                  onChange={handleSelectedRole}
                  value={value === '' ? 'Select Role' : value}
                  style={{
                    height: '40px',
                    width: '180px',
                    fontSize: '12px',
                    backgroundColor: '#EFEFEF',
                    color: '#393939',
                  }}
                >
                  <MenuItem value={'Select Role'}>Select Role</MenuItem>

                  {roleArray?.map((elem, index) => {
                    return (
                      <MenuItem value={elem} key={index}>
                        {formatRole(elem)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Button fullWidth variant="contained" type={'button'} style={{ height: '39px', marginLeft: '2%' }} onClick={handleRole}>
                <ArrowRightAltIcon />
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default RoleModal;
