import React, { useEffect, useState } from 'react';
import { Box, Checkbox, Button, Container, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import './login.scss';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getMultiplePharmacy, logout, setPharmacy } from 'app/shared/reducers/authentication';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const PharmacyModal = props => {
  const dispatch = useAppDispatch();
  const { pharmacy_list, account } = useAppSelector(state => state.authentication);
  const [pharmacyArray, setPharmacyArray] = useState(pharmacy_list);
  const navigate = useNavigate();

  useEffect(() => {
    const id = account.id;
    dispatch(getMultiplePharmacy(id));
  }, [account]);

  useEffect(() => {
    setPharmacyArray(pharmacy_list);
  }, [pharmacy_list]);

  const handleSelectedPharmacy = pharmacy => {
    dispatch(setPharmacy(pharmacy)).then(r => {});
    if (localStorage.getItem('pharmacySelected')) {
      navigate('/login/gphc');
    } else {
      navigate('/login/pharmacy');
    }
  };
  const logoutUser = () => {
    dispatch(logout());
    navigate('/login');
  };
  return (
    <Box>
      <Container component="main" style={{ width: 500, height: '40%', marginBottom: 20 }}>
        <Box
          sx={{
            boxShadow: 3,
            borderRadius: 2,
            px: 8,
            py: 6,
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Box style={{ position: 'absolute', top: 0, left: 0, padding: '10px', cursor: 'pointer' }}>
            <ArrowBackIcon onClick={logoutUser} />
          </Box>
          <Box sx={{ mb: 3 }}>
            <img src={'../../../content/logos/pharmacy-solution-logo.png'} className="login-logo" />
          </Box>

          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              mt: 2,
            }}
          >
            {pharmacyArray && pharmacyArray.length > 0
              ? pharmacyArray.map((entity, i) => (
                  <Grid item xs={4} sx={{ cursor: 'pointer' }} key={i}>
                    <Grid
                      container
                      direction={'column'}
                      alignItems="center"
                      onClick={() => {
                        handleSelectedPharmacy(entity);
                      }}
                    >
                      <Grid item style={{ border: '0.1px solid #7CB6F8', height: 80, width: 80, padding: 16, borderRadius: 8 }}>
                        <img src={'../../../content/images/pharmacy-icon.png'} alt="Icon" style={{ height: '45px' }} />
                      </Grid>
                      <Grid item style={{ fontSize: '10px', marginTop: 10 }}>
                        {entity.name}
                      </Grid>
                    </Grid>
                  </Grid>
                ))
              : 'Loading...!'}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default PharmacyModal;
