import './dashboardMenuCommp.scss';
import React, { useCallback, useEffect, useState } from 'react';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator, { listItemDecoratorClasses } from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemButton from '@mui/joy/ListItemButton';
import Typography from '@mui/joy/Typography';
import { Divider, Grid, IconButton, Menu, MenuItem } from '@mui/material';
import { NMS_TYPES, NMS_STATUS } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { authenticate, clearAuthToken, logout } from 'app/shared/reducers/authentication';
import { useNavigate } from 'react-router-dom';
import { isImportTypeAssertionContainer } from 'typescript';
import { getAllSessionsByPharmacy, getPharmacyById } from 'app/entities/session/session.reducer';
import InfoIcon from '@mui/icons-material/Info';
import GroupIcon from '@mui/icons-material/Group';
import BoyIcon from '@mui/icons-material/Boy';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
export const DashboardMenuCommp = props => {
  const { sessionCounts, handleSessionStatusValue } = props;
  const page = useAppSelector(state => state.session.pageNo);
  const rowsPerPage = useAppSelector(state => state.session.rows);
  const [showMenu, setShowMenu] = React.useState(false);
  const [index, setIndex] = React.useState(0);
  const [showOptions, setShowOptions] = React.useState(false);
  const [values, setValues] = useState({ service_id: 1, session_type: null, session_status: null });
  const [interventionCount, setInterventionCount] = useState(0);
  const [followUpCount, setFollowUpCount] = useState(0);
  const [overdueCount, setOverdueCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [notConsentCount, setNoConsentCount] = useState(0);
  const [notReachableCount, setNotReachableCount] = useState(0);
  const [isSearch, setSearch] = useState(false);
  const [selectedColor, setSelectedColor] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = useAppSelector(state => state.authentication.account);
  const pharmacyName = localStorage.getItem('pharmacy_name') === null ? '' : localStorage.getItem('pharmacy_name');
  const managerName = localStorage.getItem('manager_name') === null ? '' : localStorage.getItem('manager_name');
  const pharmacyId = localStorage.getItem('pharmacy_id') === null ? '' : localStorage.getItem('pharmacy_id');
  const pharmacyDetail = useAppSelector(state => state.session.pharmacyDetail);
  const role = localStorage.getItem('role') === null ? '' : localStorage.getItem('role');
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    handleClose();
    navigate('/login');
  };

  const getPharmacyBy = useCallback(
    async (id: number) => {
      await dispatch(getPharmacyById(id));
    },
    [Number(pharmacyId)]
  );

  useEffect(() => {
    setInterventionCount(sessionCounts ? sessionCounts['interventionCount'] : 0);
    setFollowUpCount(sessionCounts ? sessionCounts['followUpCount'] : 0);
    setOverdueCount(sessionCounts ? sessionCounts['overdueCount'] : 0);
    setCompletedCount(sessionCounts ? sessionCounts['completedCount'] : 0);
    setNoConsentCount(sessionCounts ? sessionCounts['noConsentCount'] : 0);
    setNotReachableCount(sessionCounts ? sessionCounts['noReachableCount'] : 0);
  }, [sessionCounts]);

  useEffect(() => {
    getPharmacyBy(Number(pharmacyId));
  }, [Number(pharmacyId)]);

  const handleSessionListing = e => {
    const sessionTypeValues = e.target.getAttribute('data-session-type');
    const dataIndex = e.target.getAttribute('data-index');
    const sessionStatusValues = e.target.getAttribute('data-session-status');
    setIndex(dataIndex);
    handleSessionStatusValue(sessionStatusValues);
    const data = {
      pageNo: 0,
      rows: rowsPerPage,
      q: '',
      service_id: 1,
      session_type: sessionTypeValues,
      session_status: sessionStatusValues,
      sort: {
        address: false,
        fullName: false,
        nextSession: false,
      },
    };
    setTimeout(() => {
      dispatch(getAllSessionsByPharmacy(data));
    }, 50);
  };

  return (
    <Grid lg={2.3} xs={12} sm={3} container style={{ backgroundColor: '#325C92', justifyContent: 'center' }}>
      {/* <Divider style={{ backgroundColor: 'lightgray', height: 3, alignSelf: 'flex-end', marginLeft: 0 }} /> */}

      {showMenu === false ? (
        <Grid lg={10} xs={10} sm={10} style={{ marginTop: 30, height: '50%', backgroundColor: '' }}>
          <List
            aria-label="Sidebar"
            sx={{
              '--ListItem-paddingLeft': '0px',
              '--ListItemDecorator-size': '20px',
              '--ListItem-minHeight': '10px',
              '--List-nestedInsetStart': '10px',
              [`& .${listItemDecoratorClasses.root}`]: {
                justifyContent: 'space-between',
                pr: '18px',
              },
              '& [role="button"]': {
                borderRadius: '0 2px 2px 0',
              },
              height: 300,
            }}
          >
            <ListItem>
              <ListItemButton
                onClick={() => (showOptions === false ? setShowOptions(true) : setShowOptions(false))}
                style={{
                  backgroundColor: '#7CB6F8',
                  cursor: 'pointer',
                  borderRadius: 1,
                  height: 38,
                  // '&:hover': {
                  //   backgroundColor: '#7CB6F8',
                  // },
                }} // Added cursor style for indicating clickability
              >
                <img
                  src={'../../../../content/assets/in-progress-svgrepo-com.png'} // Use the image URL from the array
                  alt="logo"
                  style={{ height: '17px', marginLeft: 24 }}
                />
                <ListItemContent
                  sx={{
                    fontWeight: 600,
                    color: 'white',
                    fontSize: 14,
                    marginLeft: 2,
                    fontFamily: 'Roboto, sans-serif',
                  }}
                >
                  In Progress
                </ListItemContent>
                {showOptions === false ? (
                  <img
                    src={'../../../../content/assets/downarrow.png'} // Use the image URL from the array
                    alt="logo"
                    style={{ height: '13px', marginLeft: 24 }}
                  />
                ) : (
                  <img
                    src={'../../../../content/assets/uparrow.png'} // Use the image URL from the array
                    alt="logo"
                    style={{ height: '13px', marginLeft: 24 }}
                  />
                )}
              </ListItemButton>
            </ListItem>
            {showOptions === true ? (
              <>
                <Divider style={{ backgroundColor: '#325C92', height: 3 }} />
                <ListItem
                  onClick={handleSessionListing}
                  data-session-status={NMS_STATUS.IN_PROGRESS}
                  data-session-type={NMS_TYPES.INTERVENTION}
                >
                  <ListItemButton
                    data-session-type={NMS_TYPES.INTERVENTION}
                    data-session-status={NMS_STATUS.IN_PROGRESS}
                    selected={index === 1}
                    style={{ backgroundColor: selectedColor === 'one' ? '#4877B1' : '#325C92' }}
                    onClick={() => {
                      setSelectedColor('one');
                    }}
                  >
                    <ListItemContent
                      data-session-type={NMS_TYPES.INTERVENTION}
                      data-index={1}
                      data-session-status={NMS_STATUS.IN_PROGRESS}
                      sx={{
                        fontWeight: 'bold',
                        color: selectedColor === 'one' ? '#4877B1' : undefined,
                        fontSize: 13,
                        marginLeft: 7,
                        height: 23,
                        marginTop: 0,
                        fontFamily: 'Roboto, sans-serif',
                      }}
                    >
                      <span
                        style={{ color: 'white' }}
                        data-session-type={NMS_TYPES.INTERVENTION}
                        data-index={1}
                        data-session-status={NMS_STATUS.IN_PROGRESS}
                      >
                        - Interventions
                      </span>
                    </ListItemContent>
                    <Typography level="body-sm" sx={{ fontWeight: 'bold', color: 'white', fontSize: 13 }}>
                      {interventionCount}
                    </Typography>
                  </ListItemButton>
                </ListItem>

                <Divider style={{ backgroundColor: '#325C92', height: 3 }} />

                <ListItem
                  onClick={handleSessionListing}
                  data-session-type={NMS_TYPES.FOLLOW_UP}
                  data-session-status={NMS_STATUS.IN_PROGRESS}
                >
                  <ListItemButton
                    data-session-type={NMS_TYPES.FOLLOW_UP}
                    data-index={2}
                    data-session-status={NMS_STATUS.IN_PROGRESS}
                    selected={index === 2}
                    style={{ backgroundColor: selectedColor === 'two' ? '#4877B1' : '#325C92' }}
                    onClick={() => {
                      setSelectedColor('two');
                    }}
                  >
                    <ListItemContent
                      data-session-type={NMS_TYPES.FOLLOW_UP}
                      data-index={2}
                      data-session-status={NMS_STATUS.IN_PROGRESS}
                      sx={{
                        fontWeight: 'bold',
                        color: selectedColor === 'two' ? '#4877B1' : undefined,
                        fontSize: 13,
                        marginLeft: 7,
                        height: 22,
                        marginTop: 0,
                        fontFamily: 'Roboto, sans-serif',
                      }}
                    >
                      <span
                        style={{ color: 'white' }}
                        data-session-type={NMS_TYPES.FOLLOW_UP}
                        data-index={2}
                        data-session-status={NMS_STATUS.IN_PROGRESS}
                      >
                        - Follow Ups
                      </span>
                    </ListItemContent>
                    <Typography level="body-sm" sx={{ fontWeight: 'bold', color: 'white', fontSize: 13 }}>
                      {followUpCount}
                    </Typography>
                  </ListItemButton>
                </ListItem>

                <Divider style={{ color: '#325C92', height: 3 }} />
              </>
            ) : null}

            {/* <Divider style={{ backgroundColor: '#325C92', height: 1 }} /> */}
            {/* <ListItem onClick={handleSessionListing}> */}
            {/*   <ListItemButton */}
            {/*     data-index={3} */}
            {/*     data-session-status={NMS_STATUS.OVERDUE} */}
            {/*     selected={index === 3} */}
            {/*     style={{ backgroundColor: selectedColor === 'three' ? '#4877B1' : '#325C92', height: 35 }} */}
            {/*     onClick={() => { */}
            {/*       setSelectedColor('three'); */}
            {/*     }} */}
            {/*   > */}
            {/*     <img */}
            {/*       data-index={3} */}
            {/*       data-session-status={NMS_STATUS.OVERDUE} */}
            {/*       src={'../../../../content/assets/Group 24.png'} // Use the image URL from the array */}
            {/*       alt="logo" */}
            {/*       style={{ height: '18px', marginRight: 10, marginLeft: 25 }} */}
            {/*     /> */}
            {/*     <ListItemContent */}
            {/*       data-index={3} */}
            {/*       data-session-status={NMS_STATUS.OVERDUE} */}
            {/*       sx={{ */}
            {/*         fontWeight: 'bold', */}
            {/*         color: selectedColor === 'three' ? '#4877B1' : undefined, */}
            {/*         fontSize: 13, */}
            {/*         marginLeft: 1, */}
            {/*         fontFamily: 'Roboto, sans-serif', */}
            {/*       }} */}
            {/*     > */}
            {/*       <span style={{ color: 'white' }} data-index={3} data-session-status={NMS_STATUS.OVERDUE}> */}
            {/*         Overdues */}
            {/*       </span> */}
            {/*     </ListItemContent> */}
            {/*     <Typography level="body-sm" sx={{ fontWeight: 'bold', color: 'white', fontSize: 14 }}> */}
            {/*       {overdueCount} */}
            {/*     </Typography> */}
            {/*   </ListItemButton> */}
            {/* </ListItem> */}

            {/* <Divider style={{ backgroundColor: 'lightgray', height: 1 }} /> */}
            {/* <ListItem onClick={handleSessionListing} data-session-status={NMS_STATUS.COMPLETED}> */}
            {/*   <ListItemButton */}
            {/*     data-index={4} */}
            {/*     data-session-status={NMS_STATUS.COMPLETED} */}
            {/*     selected={index === 4} */}
            {/*     style={{ backgroundColor: selectedColor === 'four' ? '#4877B1' : '#325C92' }} */}
            {/*     onClick={() => { */}
            {/*       setSelectedColor('four'); */}
            {/*     }} */}
            {/*   > */}
            {/*     <img */}
            {/*       data-index={4} */}
            {/*       data-session-status={NMS_STATUS.COMPLETED} */}
            {/*       src={'../../../../content/assets/completed-checkmark-done-complete-svgrepo-com.png'} // Use the image URL from the array */}
            {/*       alt="logo" */}
            {/*       style={{ height: '19px', marginRight: 10, marginLeft: 20 }} */}
            {/*     /> */}
            {/*     <ListItemContent */}
            {/*       data-index={4} */}
            {/*       data-session-status={NMS_STATUS.COMPLETED} */}
            {/*       sx={{ */}
            {/*         fontWeight: 'bold', */}
            {/*         color: selectedColor === 'four' ? '#4877B1' : undefined, */}
            {/*         fontSize: 13, */}
            {/*         marginLeft: 0.7, */}
            {/*         fontFamily: 'Roboto, sans-serif', */}
            {/*       }} */}
            {/*     > */}
            {/*       <span style={{ color: 'white' }} data-index={4} data-session-status={NMS_STATUS.COMPLETED}> */}
            {/*         Completed */}
            {/*       </span> */}
            {/*     </ListItemContent> */}
            {/*     <Typography level="body-sm" sx={{ fontWeight: 'bold', color: 'white', fontSize: 14 }}> */}
            {/*       {completedCount} */}
            {/*     </Typography> */}
            {/*   </ListItemButton> */}
            {/* </ListItem> */}

            <Divider style={{ backgroundColor: 'lightgray', height: 1 }} />
            <ListItem onClick={handleSessionListing} data-session-status={NMS_STATUS.NO_CONSENT}>
              <ListItemButton
                data-index={5}
                data-session-status={NMS_STATUS.NO_CONSENT}
                selected={index === 5}
                style={{ backgroundColor: selectedColor === 'five' ? '#4877B1' : '#325C92' }}
                onClick={() => {
                  setSelectedColor('five');
                }}
              >
                <img
                  data-index={5}
                  data-session-status={NMS_STATUS.NO_CONSENT}
                  src={'../../../../content/assets/no-svgrepo-com.png'} // Use the image URL from the array
                  alt="logo"
                  style={{ height: '17px', marginRight: 10, marginLeft: 20 }}
                />
                <ListItemContent
                  data-index={5}
                  data-session-status={NMS_STATUS.NO_CONSENT}
                  sx={{
                    fontWeight: 'bold',
                    color: selectedColor === 'five' ? '#4877B1' : undefined,
                    fontSize: 13,
                    marginLeft: 1,
                    fontFamily: 'Roboto, sans-serif',
                  }}
                >
                  <span style={{ color: 'white' }} data-index={5} data-session-status={NMS_STATUS.NO_CONSENT}>
                    No Consent
                  </span>
                </ListItemContent>
                <Typography level="body-sm" sx={{ fontWeight: 'bold', color: 'white', fontSize: 13 }}>
                  {notConsentCount}
                </Typography>
              </ListItemButton>
            </ListItem>

            <Divider style={{ backgroundColor: 'lightgray', height: 1 }} />
            <ListItem onClick={handleSessionListing} data-session-status={NMS_STATUS.NO_REACHABLE}>
              <ListItemButton
                data-index={6}
                data-session-status={NMS_STATUS.NO_REACHABLE}
                selected={index === 6}
                style={{ backgroundColor: selectedColor === 'six' ? '#4877B1' : '#325C92' }}
                onClick={() => {
                  setSelectedColor('six');
                }}
              >
                <img
                  data-index={6}
                  data-session-status={NMS_STATUS.NO_REACHABLE}
                  src={'../../../../content/assets/phone-xmark-alt-svgrepo-com.png'} // Use the image URL from the array
                  alt="logo"
                  style={{ height: '17px', marginRight: 10, marginLeft: 20 }}
                />
                <ListItemContent
                  data-index={6}
                  data-session-status={NMS_STATUS.NO_REACHABLE}
                  sx={{
                    fontWeight: 'bold',
                    color: selectedColor === 'six' ? '#4877B1' : '#325C92',
                    fontSize: 13,
                    marginLeft: 1,
                  }}
                >
                  <span style={{ color: 'white' }} data-index={6} data-session-status={NMS_STATUS.NO_REACHABLE}>
                    Not Contactable
                  </span>
                </ListItemContent>
                <Typography level="body-sm" sx={{ fontWeight: 'bold', color: 'white', fontSize: 13 }}>
                  {notReachableCount}
                </Typography>
              </ListItemButton>
            </ListItem>
          </List>

          {/*<div className="pharmacy_div">*/}
          {/*  <div*/}
          {/*    style={{*/}
          {/*      justifyContent: 'space-between',*/}
          {/*      marginLeft: '20px',*/}
          {/*      width: '100%',*/}
          {/*      overflow: 'hidden',*/}
          {/*      alignSelf: 'center',*/}
          {/*      marginBottom: 8,*/}
          {/*      marginTop: 8,*/}
          {/*    }}*/}
          {/*  >*/}

          {/*    <div className="detail_block">*/}
          {/*      <InfoIcon className={"icon_height"} />*/}
          {/*      <h1 className="pharmacy_heading_detail">Pharmacy Detail :</h1>*/}
          {/*    </div>*/}

          {/*    /!*<div className="detail_block">*!/*/}
          {/*    /!*  <GroupIcon className={"icon_height"}/>*!/*/}
          {/*    /!*  <span className="pharmacy_heading">Group :</span>*!/*/}
          {/*    /!*  <span className="pharmacy_text">Oakwood</span>*!/*/}
          {/*    /!*</div>*!/*/}

          {/*    <div className="detail_block">*/}
          {/*      <LocalPharmacyIcon className={"icon_height"} />*/}
          {/*      <span className="pharmacy_heading">Name :</span>*/}
          {/*      <span className="pharmacy_text">{pharmacyDetail?.name}</span>*/}
          {/*    </div>*/}

          {/*    <div className="detail_block">*/}
          {/*      <BoyIcon className={"icon_height"} />*/}
          {/*      <span className="pharmacy_heading">Manager Name :</span>*/}
          {/*      <span className="pharmacy_text">{pharmacyDetail?.manager_name}</span>*/}
          {/*    </div>*/}

          {/*    <div>*/}
          {/*      <div className={"detail_block"}>*/}
          {/*        <LocationOnIcon className="icon_height" />*/}
          {/*        <span className="pharmacy_heading">Address :</span>*/}
          {/*      </div>*/}
          {/*      <p className="pharmacy_text">*/}
          {/*        {pharmacyDetail?.address}*/}
          {/*      </p>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <Grid
            lg={12}
            container
            sx={{
              flexDirection: 'row',
              marginLeft: 0,
              marginTop: 0,
              maxHeight: 600,
            }}
          >
            <div
              style={{
                width: '100%',
                height: 38,
                backgroundColor: 'rgb(124, 182, 248)',
                borderRadius: 1,
                alignSelf: 'center',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <h1
                style={{
                  color: '#FFFFFF',
                  fontSize: 14,
                  fontWeight: 600,
                  marginTop: 8,
                  marginLeft: 80,
                  fontFamily: 'Roboto, sans-serif',
                }}
              >
                Pharmacy Details
              </h1>
            </div>

            <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginTop: 10, width: 'inherit' }}>
              <div style={{ display: 'flex', alignSelf: 'center', alignItems: 'center' }}>
                <div style={{ height: 35, width: 90, backgroundColor: '' }}>
                  <h1
                    style={{
                      color: 'white',
                      fontSize: 13,
                      marginTop: 8,
                      marginLeft: 5,
                      fontWeight: 400,
                      alignSelf: 'center',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    Name
                  </h1>
                </div>
                <div style={{ width: 150, backgroundColor: '' }}>
                  <h1
                    style={{
                      fontSize: 13,
                      color: 'white',
                      marginTop: 8,
                      marginLeft: 0,
                      fontWeight: 400,
                      alignSelf: 'center',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    {pharmacyDetail?.name}
                  </h1>
                </div>
              </div>
              <Divider style={{ backgroundColor: 'lightgrey', marginTop: 0, alignSelf: 'center' }} />
            </div>
            <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginTop: 10, width: 'inherit' }}>
              <div style={{ display: 'flex', alignSelf: 'center', alignItems: 'center' }}>
                <div style={{ height: 35, width: 90, backgroundColor: '' }}>
                  <h1
                    style={{
                      color: 'white',
                      fontSize: 13,
                      marginTop: 8,
                      marginLeft: 5,
                      fontWeight: 400,
                      alignSelf: 'center',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    Manager
                  </h1>
                </div>
                <div style={{ width: 150, backgroundColor: '' }}>
                  <h1
                    style={{
                      fontSize: 13,
                      color: 'white',
                      marginTop: 8,
                      marginLeft: 0,
                      fontWeight: 400,
                      alignSelf: 'center',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    {pharmacyDetail?.manager_name}
                  </h1>
                </div>
              </div>
              <Divider style={{ backgroundColor: 'lightgrey', marginTop: 0, alignSelf: 'center' }} />
            </div>
            <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginTop: 10, width: 'inherit' }}>
              <div style={{ display: 'flex', alignSelf: 'center', alignItems: 'center' }}>
                <div style={{ height: 35, width: 90, backgroundColor: '' }}>
                  <h1
                    style={{
                      color: 'white',
                      fontSize: 13,
                      marginTop: 8,
                      marginLeft: 5,
                      fontWeight: 400,
                      alignSelf: 'center',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    Telephone
                  </h1>
                </div>
                <div style={{ width: 150, backgroundColor: '' }}>
                  <h1
                    style={{
                      fontSize: 13,
                      color: 'white',
                      marginTop: 8,
                      marginLeft: 0,
                      fontWeight: 400,
                      alignSelf: 'center',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    {pharmacyDetail?.contact_number}
                  </h1>
                </div>
              </div>
              <Divider style={{ backgroundColor: 'lightgrey', marginTop: 0, alignSelf: 'center' }} />
            </div>
            <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginTop: 10, width: 'inherit' }}>
              <div style={{ display: 'flex', alignSelf: 'center', alignItems: 'center' }}>
                <div style={{ height: 35, width: 90, backgroundColor: '' }}>
                  <h1
                    style={{
                      color: 'white',
                      fontSize: 13,
                      marginTop: 8,
                      marginLeft: 5,
                      fontWeight: 400,
                      alignSelf: 'center',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    Address
                  </h1>
                </div>
                <div style={{ width: 150, backgroundColor: '' }}>
                  <h1
                    style={{
                      color: 'white',
                      fontSize: 13,
                      marginTop: 8,
                      marginLeft: 0,
                      fontWeight: 400,
                      alignSelf: 'center',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    {pharmacyDetail?.address}
                  </h1>
                </div>
              </div>
              <Divider style={{ backgroundColor: 'lightgrey', marginTop: 0, alignSelf: 'center' }} />
            </div>
          </Grid>
          <div className="logout_div">
            <img src={'../../../../content/assets/profile1.png'} alt="logo" style={{ height: '35px' }} onClick={() => {}} />

            <div
              style={{
                justifyContent: 'space-between',
                marginLeft: 5,
                width: '70%',
                overflow: 'hidden',
                alignSelf: 'center',
                marginTop: 8,
              }}
            >
              <div>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
              <h1 className="logout_text"> {role === null || role === 'null' ? '' : role?.replace(/"/g, '')}</h1>
              <h1
                style={{
                  fontSize: 10,
                  color: 'white',
                  marginLeft: 0.5,
                  fontFamily: 'Roboto, sans-serif',
                  marginTop: -3,
                }}
              >
                {pharmacyName.replace(/"/g, '')}
              </h1>
              <h1
                style={{
                  fontSize: 10,
                  color: 'white',
                  marginLeft: 0.5,
                  fontFamily: 'Roboto, sans-serif',
                  marginTop: -3,
                }}
              >
                {managerName === null || managerName === 'null' ? '' : managerName.replace(/"/g, '')}
              </h1>
            </div>
            <IconButton
              onClick={handleClick}
              style={{
                backgroundColor: '',
                marginRight: 5,
                height: 30,
                width: 20,
                padding: '0px',
                display: 'flex',
                borderRadius: 5,
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
            >
              <img src={'../../../../content/assets/threedots.png'} alt="logo" style={{ height: '25px' }} />
            </IconButton>
          </div>
        </Grid>
      ) : (
        []
      )}
    </Grid>
  );
};
