import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TablePagination,
  Tooltip,
} from '@mui/material';
import { getAllSessionsByPharmacy } from 'app/entities/session/session.reducer';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import './admin-manage-role.scss';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import useManageRole from '../hooks/useManageRole';
import RichDataTable from '../rich-data-table/RichDataTable';

interface ISessionListParams {
  service_id: any;
  session_status: any;
  session_type: any;
  pageNo: any;
  searchParams: any;
  rows: any;
  q: any;
}

export const AdminManageRole = props => {
  const { states, handlers } = useManageRole();
  const { userId, open, pageNo, limit, totalCount, allRolesListing, allRoles, searchCreteria, emptyCellCount, emptyRowCount } = states;

  const {
    downloadRolesInExcelFile,
    handleSearchChange,
    handleRole,
    handleRefresh,
    handledateFrom,
    handledateTill,
    handleChangePage,
    handleChangeRowsPerPage,
    handleClose,
    handleDelete,
    setOpen,
    setUserIdAndOpenStatus,
    handleEdit,
  } = handlers;

  const navigate = useNavigate();

  return (
    <>
      <Grid lg={9.5} xs={8} container style={{ backgroundColor: 'transparent', marginTop: 20, marginLeft: 20, maxHeight: '100%' }}>
        <Grid lg={11.8} xs={12} container>
          <div style={{ width: '100%', display: 'flex', marginTop: '2%', marginBottom: '1%' }}>
            <div style={{ width: '95%', display: 'flex', backgroundColor: '' }}>
              <FormControl>
                <Select
                  id="demo-simple-select"
                  onChange={handleRole}
                  value={searchCreteria.role === '' ? 'All Role' : searchCreteria.role}
                  style={{
                    height: '40px',
                    width: '180px',
                    fontSize: '12px',
                    backgroundColor: '#EFEFEF',
                    color: '#393939',
                  }}
                >
                  <MenuItem value={'All Role'}>All Role</MenuItem>

                  {allRoles.map((elem, index) => {
                    return (
                      <MenuItem value={elem.name} key={elem.name}>
                        {elem.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <div style={{ display: 'flex', width: '40%', backgroundColor: '', marginLeft: '2%' }}>
                <Tooltip title="Date From">
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                    <DatePicker
                      className="clock"
                      onChange={newValue => handledateFrom(newValue)}
                      format="DD/MM/YYYY"
                      value={searchCreteria.from === '' ? null : searchCreteria.from}
                    />
                  </LocalizationProvider>
                </Tooltip>
                <Tooltip title="Date Till">
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                    <DatePicker
                      value={searchCreteria.to === '' ? null : searchCreteria.to}
                      className="clock"
                      onChange={newValue => handledateTill(newValue)}
                      sx={{ marginLeft: '5%' }}
                      format="DD/MM/YYYY"
                    />
                  </LocalizationProvider>
                </Tooltip>
              </div>
            </div>

            <Tooltip title="Refresh">
              <IconButton
                onClick={() => handleRefresh()}
                style={{
                  backgroundColor: '#7CB6F8',
                  marginLeft: 5,
                  height: 40,
                  width: 40,
                  padding: '0px',
                  justifyContent: 'center',
                  borderRadius: 5,
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                <img src={'../../../../content/assets/refresh.png'} alt="logo" style={{ height: '17px', marginRight: 0, marginLeft: 0 }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Download">
              <IconButton
                onClick={() => downloadRolesInExcelFile()}
                style={{
                  backgroundColor: '#7CB6F8',
                  marginLeft: 5,
                  height: 40,
                  width: 40,
                  padding: '0px',
                  borderRadius: 5,
                  justifyContent: 'center',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                <img
                  src={'../../../../content/assets/download2.png'}
                  alt="logo"
                  style={{ height: '17px', marginRight: 0, marginLeft: 0 }}
                />
              </IconButton>
            </Tooltip>

            <Tooltip title="Add New Role">
              <IconButton
                onClick={() => {
                  localStorage.setItem('lastActiveTabAdmin', JSON.stringify({ selectedColor: 'four', index: 4 }));
                  navigate('/admin/add-role');
                }}
                style={{
                  backgroundColor: '#7CB6F8',
                  marginLeft: 5,
                  height: 40,
                  width: 100,
                  padding: '0px',
                  display: 'flex',
                  borderRadius: 5,
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                <img src={'../../../../content/assets/Page-4.png'} alt="logo" style={{ height: '12px', marginRight: 0, marginLeft: 0 }} />
                <h1 style={{ fontSize: 12, color: 'white', marginLeft: 7, marginTop: 10 }}>Add New</h1>
              </IconButton>
            </Tooltip>
          </div>

          <Grid lg={12} xs={12} container style={{ backgroundColor: 'white' }}>
            <Grid lg={12} xs={12} item style={{ height: 30, marginTop: 5 }}>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                <FormControl sx={{ width: '100%', height: '11%' }}>
                  <OutlinedInput
                    style={{ borderRadius: 50, height: '100%', width: '100%' }}
                    placeholder="Search here"
                    onChange={handleSearchChange}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton color="inherit" style={{ height: 30, width: 30 }}>
                          <img
                            src={'../../../../content/assets/search-alt-svgrepo-com (1).png'} // Use the image URL from the array
                            alt="logo"
                            style={{ height: '15px' }}
                          />
                        </IconButton>
                      </InputAdornment>
                    }
                    inputProps={{ style: { fontSize: 13 } }}
                    classes={{ notchedOutline: 'white-outline' }}
                  />
                </FormControl>
              </div>
            </Grid>
          </Grid>
          <RichDataTable
            limit={limit}
            page={pageNo}
            count={totalCount}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            emptyRowCount={emptyRowCount}
            emptyCellCount={emptyCellCount}
            dataIndexs={[
              'index',
              'internalUser.firstName',
              'internalUser.formattedAuthorities',
              'contact_number',
              'internalUser.email',
              'internalUser.status',
            ]}
            dataSource={allRolesListing}
            headers={['ID', 'Name', 'Role', 'Contact No.', 'Email Address', 'Status']}
            showEditDeletButton={true}
            handleDelete={setUserIdAndOpenStatus}
            handleEdit={handleEdit}
          />
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to delete.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={() => handleDelete(userId)} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default AdminManageRole;
