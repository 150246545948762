import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Button,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  List,
  ListItemText,
} from '@mui/material';
import './login.scss';
import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';
import { handleLocumLogin, logout } from 'app/shared/reducers/authentication';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import ListItem from '@mui/joy/ListItem';
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const LocumModal = props => {
  const { account } = useAppSelector(state => state.authentication);
  const dispatch = useAppDispatch();
  const [values, setValues] = useState({ first_name: '', gphc_number: '' });
  const [fieldError, setFieldError] = useState({
    first_name: false,
    last_name: false,
    gphc_number: false,
  });
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const agreement = () => {
    setOpen(false);
    navigate('/');
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(logout());
    navigate('/login');
  };

  useEffect(() => {
    setValues(oldValues => ({
      ...oldValues,
      first_name: account && account.firstName,
      last_name: account && account.lastName,
    }));
    setFieldError(oldValues => ({ ...oldValues, ['gphc_number']: true }));
  }, [account]);

  const setFormValues = e => {
    setValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
    if (e.target.name === 'first_name' || e.target.name === 'gphc_number') {
      checkSingleFieldValidations(e);
    }
  };
  const checkSingleFieldValidations = e => {
    if (e.target.value === undefined || e.target.value === '' || e.target.value === null) {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: true }));
    } else {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: false }));
    }
  };

  const checkValidationsBeforeSubmit = () => {
    if (values['first_name'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['first_name']: true }));
    }

    if (values['gphc_number'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['gphc_number']: true }));
    }
  };

  const isAllowedToSubmit = () => {
    checkValidationsBeforeSubmit();
    if (
      !fieldError['first_name'] &&
      !fieldError['gphc_number'] &&
      values['first_name'] !== undefined &&
      values['gphc_number'] !== undefined
    ) {
      setFieldError(oldValues => ({ ...oldValues, ['gphc_number']: false }));
      return true;
    } else {
      return false;
    }
  };

  const handleLocumLoginDetails = () => {
    if (isAllowedToSubmit() && account && account.id) {
      const appUserId = account.id;
      dispatch(handleLocumLogin(appUserId, values.first_name, null, values.gphc_number));
    }
  };
  useEffect(() => {
    if (localStorage.getItem('locumVerified')) {
      setOpen(true);
    } else {
      navigate('/login/gphc');
    }
  }, [localStorage.getItem('locumVerified')]);

  const logoutUser = () => {
    dispatch(logout());
    navigate('/login');
  };
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleLocumLoginDetails();
    }
  };
  return (
    <Box>
      <Container component="main" maxWidth="sm" style={{ marginBottom: 20 }}>
        <Box
          sx={{
            boxShadow: 3,
            borderRadius: 2,
            px: 8,
            py: 6,
            marginTop: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Box style={{ position: 'absolute', top: 0, left: 0, padding: '10px', cursor: 'pointer' }}>
            <ArrowBackIcon onClick={logoutUser} />
          </Box>
          <Box sx={{ mb: 3 }}>
            <img src={'../../../content/logos/pharmacy-solution-logo.png'} className="login-logo" />
          </Box>
          <Typography component="h1" fontWeight={'600'} variant="h6">
            Locum Details
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }} onKeyDown={handleKeyDown}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="firstname"
              label="First Name"
              value={values ? values.first_name : ''}
              type="text"
              error={fieldError['first_name']}
              onChange={setFormValues}
              id="firstname"
              autoComplete="first_name"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="gphc_number"
              label="Gphc Number"
              error={fieldError['gphc_number']}
              onChange={setFormValues}
              type="text"
              id="gphcnumber"
              autoComplete="gphc_number"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button onClick={handleLocumLoginDetails} fullWidth variant="contained" sx={{ mt: 3, mb: 2, height: 40 }}>
              Continue
            </Button>
          </Box>
        </Box>
      </Container>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md" // Set a reasonable maxWidth, 'md' stands for medium
        fullWidth // Ensure the dialog uses full width
      >
        <DialogTitle>{'Instructions.'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <List>
              <ListItem>
                <ListItemText primary="1. Please familiarise yourself with the Pharmacy name and address prior to calling the patients" />
              </ListItem>
              <ListItem>
                <ListItemText primary="2. I confirm I will adhere to NHS New Medicines Service Service specification" />
              </ListItem>
              <ListItem>
                <ListItemText primary="3. I confirm I will adhere to Pharmacy Solutions SOP's" />
              </ListItem>
              <ListItem>
                <ListItemText primary="4. I confirm I have appropriate Indemnity insurance in place to conduct the new medicines service" />
              </ListItem>
              <ListItem>
                <ListItemText primary="5. I confirm I have conducted the CPPE training for the New Medicines Service consultation" />
              </ListItem>
              <ListItem>
                <ListItemText primary="6. I confirm I will maintain patient confidentiality throughout my practice" />
              </ListItem>
              <ListItem>
                <ListItemText primary="7. Any NMS I can conduct not in line with the service specification will not be paid" />
              </ListItem>
              <ListItem>
                <ListItemText primary="8. Any NMS which are conducted fraudulently or do not adhere to the service specification will be reported" />
              </ListItem>
              <ListItem>
                <ListItemText primary="9. I confirm I will write accurate and detailed intervention notes" />
              </ListItem>
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Decline</Button>
          <Button onClick={agreement}>Accept</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default LocumModal;
